import React, { useEffect, useState } from "react";
import { message, Modal, notification } from "antd";
import CalendlyPopup from "./CalendlyPopup";
import axios from "axios";
import { Config } from "../../Config";
import Loader from "../Loader";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import moment from "moment";

const MakeAppointment = ({ cta, projectName, data , projectData , pricelist, Shifts , Dates }) => {
  const [contact, setContact] = useState(false);
  const [Project, setProject] = useState("");
  const [Plot, setPlot] = useState("");
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Number, setNumber] = useState("");
  const [Message, setMessage] = useState("");
  const [Meet,setmeet] = useState(0);
  const [date,setdate] = useState("");
  const [time , settime] = useState("");
  const [errors, setErrors] = useState({});  // Error state to handle validation
  const [loading,setloading] =useState(false);

  const [Slots, setSlots] = useState([])


  useEffect(() => {

    function getSlots(schedule) {
        return generateTimeSlots(schedule?.from_time, schedule?.to_time, schedule?.interval)
    }

    if (Shifts?.length) {

      console.log(Shifts);

      const Morning =  getSlots(Shifts[0])
      const Afternoon = getSlots(Shifts[1])
      const Evening = getSlots(Shifts[2])

      const allSlots = [
          ...Morning,
          ...Afternoon,
          ...Evening
      ];

      // Store all slots in state
      setSlots(allSlots);
    }
  },[Shifts])

  // converting slots as per requirements

  const generateTimeSlots = (start, end, interval) => {
    const slots = [];
    const startTime = new Date(`1970-01-01T${(start)}`);
    const endTime = new Date(`1970-01-01T${(end)}`);

    while (startTime <= endTime) {

      let slotEndTime = new Date(startTime); // Copy the startTime
      slotEndTime.setMinutes(startTime.getMinutes() + parseInt(interval));


      slots.push({
        startTime: formatTime(startTime),  // Formatted start time
        endTime: formatTime(slotEndTime),  // Formatted end time
      });

      startTime.setMinutes(startTime.getMinutes() + parseInt(interval)); // Increment by 30 minutes
    }

    return slots;
  };

  const formatTime = (date) => {
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const modifier = hours >= 12 ? 'pm' : 'am';

      hours = hours % 12 || 12; // Convert 0 to 12
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}${modifier}`;
  };

  // Validate the fields
  const validateForm = () => {
    const errors = {};
    if (!Project) errors.project = "Project is required.";
    if (!Name) errors.name = "Name is required.";
    if (!Email) errors.email = "Email is required.";
    if (!Number) errors.number = "Contact number is required.";
    if (!Message) errors.message = "Message is required.";
    setErrors(errors);
    return Object.keys(errors).length === 0;  // Return true if no errors
  };

  const onHandleClick = async () => {
    setContact(true);
  };

  const [OpenCalender , setopenCalender] = useState(false);

  const para_text = data?.schedule_description?.replace("$project", projectName || " Project");
  const btn_text = data?.schedule_button?.replace("$project", projectName || " Project");

  const handleSubmit = async() => {
    // Check for validation before submitting
    if (validateForm()) {
      try{

        setloading(true);

        const formdata = new FormData();

        formdata.append("project",Project);
        formdata.append("plot",Plot);
        formdata.append("name",Name);
        formdata.append("email",Email);
        formdata.append("phone",Number);
        formdata.append("message",Message);
        formdata.append("meetingtype",Meet);
        formdata.append("from_time",time.startTime);
        formdata.append("to_time",time.endTime);
        formdata.append("date",moment(date).format("YYYY-MM-DD"));

        const response = await axios.post(`${Config.apiUrl}/schedulevisit`,formdata);

        if(response?.data?.status === true){
          notification.open({
              message: (
                <div className="d-flex align-items-center">
                  <div className="pr-20">
                    <i
                      className="bi bi-check2-circle text-success me-2"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </div>
                  <div className="succes-message">Visit Scheduled Successfully</div>
                </div>
              ),
          });
          setContact(false);
          setloading(false);
        }else{
          notification.open({
              message: (
                <div className="d-flex align-items-center">
                  <div className="pr-20">
                    <i
                      className="bi bi-x-circle text-danger me-2"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </div>
                  <div className="succes-message">Sorry Something went Wrong. </div>
                </div>
              ),
          });
          setloading(false);
        }


      }catch(e){
        setloading(false);
        notification.open({
              message: (
                <div className="d-flex align-items-center">
                  <div className="pr-20">
                    <i
                      className="bi bi-x-circle text-danger me-2"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </div>
                  <div className="succes-message">Sorry Something went Wrong. </div>
                </div>
              ),
              description:e.message
          });
          
      }
    }
  };

  const blockedDates = Dates?.dates?.split(',').map(dateStr => {
      const [day, month, year] = dateStr.split('-');
      return new Date(year, month - 1, day); // Month is 0-indexed
  });

  // Function to check if the date is in the blocked list
  const isBlockedDate = (dateToCheck) => {
      return blockedDates?.some((blockedDate) => 
          blockedDate.toDateString() === dateToCheck.toDateString()
      );
  };

  const handledata = (e) => {
    setdate(e);
    setopenCalender(false);
  }

  return (
    <div>
      {loading && <Loader/>}
      <div
        className="cta-section bg-cta "
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(${Config.imageUrl}/${data?.schedule_bg})`,
          backgroundBlendMode: 'darken',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className="container  py-5">
          <div className="text-center text-white">
            <h2 className="display-6 fw-semibold text-capitalize">{data?.schedule_title}</h2>
            <p className="col-md-10" dangerouslySetInnerHTML={{ __html: para_text }} />
          </div>
          <div className="mt-5 text-center">
            <button className="button text-decoration-none" onClick={onHandleClick}>
              {btn_text}
            </button>
          </div>
        </div>
      </div>

      <Modal
        title={btn_text}
        open={contact}
        onCancel={() => setContact(false)}
        className="contact-modal"
        footer={null}
        width={1100}
      >
        <div className="mt-4 border border-2 p-3">
          <div className="row pb-3" style={{ borderBottom: "2px solid #dee2e6" }}>
            <div className="col-xl-4">
              <label>Select Project<span className="text-danger">*</span></label>
              <select
                className={`form-select border border-2 ${errors.project ? "is-invalid" : ""}`}
                value={Project}
                onChange={(e) => setProject(e.target.value)}
              >
                <option value="">Select Project</option>
                {
                  projectData?.map((item) => (
                    <option value={item?.id}>{item?.name}</option>
                  ))
                }
              </select>
              {errors.project && <span className="text-danger">{errors.project}</span>}
            </div>
            <div className="col-xl-4">
              <label>Select Size</label>
              <select
                className={`form-select border border-2`}
                value={Plot}
                onChange={(e) => setPlot(e.target.value)}
              >
                <option value="">Select Size</option>
                {
                  pricelist
                    ?.filter((item) => item?.project_id == Project) // Filter by projectId
                    .flatMap((item) => {
                      let prices = item.price;

                      // Convert `prices` to an array if it's a string
                      if (typeof prices === "string") {
                        try {
                          prices = JSON.parse(prices);
                        } catch (e) {
                          prices = prices.split(";").map((priceString) => priceString.split(","));
                        }
                      }

                      // Ensure `prices` is an array
                      if (!Array.isArray(prices)) {
                        prices = [];
                      } else {
                        prices = prices.map((priceItem) =>
                          typeof priceItem === "string" ? priceItem.split(",") : priceItem
                        );
                      }

                      // Process and return mapped price data
                      return prices.flatMap((subArray) =>
                        subArray.map((priceRow) => {
                          const values = priceRow.split(","); // Convert string into an array
                          return {
                            size: values[0] || "", // 0th index -> size
                            value: values[2] || "", // 1st index -> option value
                            title: `${values[0] || ""} Sq ft, Amount ${values[2] || ""}`, // 2nd index -> registrationAmount
                          };
                        })
                      );
                    })
                    .map((priceItem, index) => (
                      <option key={index} value={priceItem.size}>
                        {priceItem.size} sq Ft
                      </option>
                    ))
                }
              </select>
            </div>
            <div className="col-xl-4">
              <label>Select Meeting Type</label>
              <select
                className={`form-select border border-2`}
                value={Meet}
                onChange={(e) => setmeet(e.target.value)}
              >
                <option value={0}>Face to Face</option>
                <option value={1}>Virtual Tour</option>
                <option value={2}>Online presentation</option>
                <option value={3}>Site visit</option>
              </select>
            </div>
          </div>

          {
            Project && (
              <div className="">
                <h5 className="py-4 mb-0">Person Details:</h5>
                <div className="row">
                  <div className="col-xl-6">
                    <label>Name<span className="text-danger">*</span></label>
                    <input
                      className={`form-field border border-2 ${errors.name ? "is-invalid" : ""}`}
                      placeholder="Enter your name"
                      value={Name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && <span className="text-danger">{errors.name}</span>}
                  </div>
                  <div className="col-xl-6">
                    <label className="">Email Address<span className="text-danger">*</span></label>
                    <input
                      className={`form-field border border-2 ${errors.email ? "is-invalid" : ""}`}
                      placeholder="Enter your email address"
                      value={Email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <span className="text-danger">{errors.email}</span>}
                  </div>
                  <div className="col-xl-4 mt-3">
                    <label>Contact Number<span className="text-danger">*</span></label>
                    <input
                      className={`form-field border border-2 ${errors.number ? "is-invalid" : ""}`}
                      placeholder="Enter your contact number"
                      max={10}
                      onKeyPress={(e) => {
                        // Allow only numeric input (0-9)
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={Number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    {errors.number && <span className="text-danger">{errors.number}</span>}
                    
                  </div>
                  <div className="col-xl-4 mt-3">
                    <label>Meeting Date<span className="text-danger">*</span></label>
                    <div className="form-field border border-2 d-flex justify-content-between" style={{height:"46px" , position:"relative"}} onClick={() => setopenCalender(!OpenCalender)}>
                      {date ? moment(date).format("DD/MM/YYYY") : "Select Meeting Date"}
                      <i className="bi bi-calendar-event"></i>
                    {
                      OpenCalender && (
                        <div style={{position:"absolute" , left:"0" , bottom:"120%"}}>
                          <Calendar 
                              minDate={new Date()} 
                              onChange={(e) => {
                                handledata(e)
                              }} 
                              value={date} 
                              tileDisabled={({ date }) => isBlockedDate(date)}
                          />
                        </div>
                      ) 
                    }
                    
                    </div>

                    {errors.date && <span className="text-danger">{errors.date}</span>}
                    
                  </div>
                  <div className="col-xl-4 mt-3">
                    <label>Meeting Time<span className="text-danger">*</span></label>
                    <select
                      className={`form-select border border-2 ${errors.time ? "is-invalid" : ""}`}
                      placeholder="Select meeting time"
                      value={time ? `${time.startTime} - ${time.endTime}` : ""}  // Store both start and end time as the value
                      onChange={(e) => {
                        const selectedSlot = Slots.find(slot => `${slot.startTime} - ${slot.endTime}` === e.target.value);
                        settime(selectedSlot);  // Store the entire slot object in state
                      }}
                    >
                      <option value="">Select Time</option>
                      {
                        Slots?.map((item, index) => (
                          <option key={index} value={`${item.startTime} - ${item.endTime}`}>
                            {item.startTime} - {item.endTime}
                          </option>
                        ))
                      }
                    </select>
                    {errors.time && <span className="text-danger">{errors.time}</span>}
                  </div>
                  <div>
                  <label className="mt-3">Message<span className="text-danger">*</span></label>
                    <input
                      className={`form-field border border-2 ${errors.message ? "is-invalid" : ""}`}
                      placeholder="Enter message"
                      value={Message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                    {errors.message && <span className="text-danger">{errors.message}</span>}
                </div>
                <div className="text-center mt-3">
                  <button
                    className="button bg-green text-white"
                    onClick={() => handleSubmit()}  // Call handleSubmit when clicked
                  >
                    Schedule Now
                  </button>
                </div>
              </div>
            )
          }
        </div>
      </Modal>
    </div>
  );
};

export default MakeAppointment;
