import { useEffect, useState } from "react";
import Loader from "./Loader";
import Navbar from "./Navbar";
import Footer from "./Footer";
import moment from "moment";
import axios from "axios";
import { Config } from "../Config";
import NoVideoFound from "../assets/Images/NoVideo.jpg";

const sectionStyle = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "white"
};

const Testimonials = () => {
    const [visibleCount, setVisibleCount] = useState(2);
    const [Data,setData] = useState([])
    const [titles, settitles] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`${Config.apiUrl}/testimonials`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            const data = response.data.testimonial;
            settitles(response?.data?.titles)

            setData(data);
    
            setLoading(false);
          })
          .catch(error => {
            console.error('There was a problem fetching the data:', error);
            setLoading(false);
          });
      }, []);

    const handleLoadMore = () => {
        setVisibleCount((prev) => prev + 2);
    };

    return (
        <>
            {loading && <Loader />}
            <Navbar />

            <div className="text-center py-5" style={{
                    ...sectionStyle,
                    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${Config.imageUrl}${titles[0]?.bg})`,
                }}>
                <div className="container-box py-4">
                    <div className="Testimonials-Banner">
                        <h1>{titles[0]?.title}</h1>
                        <h5>{titles[0]?.subtitle}</h5>
                    </div>
                </div>
            </div>

            <section className="pt-4 mt-2 mb-4 pb-2">
                <div className="container-box">
                    <div className="Testimonials pb-4">
                        {Data.map((item, index) => (
                            <div key={index} className="row mb-5 py-4 align-items-center">
                                {index % 2 !== 0 ? (
                                    <>
                                        <div className="col-md-6 text-center">
                                            {item?.video ? (
                                                <video
                                                    src={`${Config.imageUrl}testimonials/${item.video}`}
                                                    controlsList="nodownload noremoteplayback"
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    controls
                                                    className="Testimonial-video w-100 h-auto"
                                                ></video>
                                            ) : (
                                                <img
                                                    src={NoVideoFound}
                                                    alt="No Video Found"
                                                    className="w-75 h-auto object-fit-cover"
                                                />
                                            )}
                                        </div>
                                        <div className="col-md-6 bg-light-green mb-0 p-3">
                                            <div className="d-flex gap-4 align-items-center">
                                                <img src={`${Config.imageUrl}testimonials/${item?.image}`} className="testimonials-image" />
                                                <div>
                                                    <h2 className="text-green">{item.name}</h2>
                                                    <h5>{item.designation} , {item.company_name}</h5>
                                                </div>
                                            </div>
                                            <p className="Testimonial-des mt-3" dangerouslySetInnerHTML={{ __html: item.comment }}></p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-md-6 text-center order-md-2">
                                            {item?.video ? (
                                                <video
                                                    src={`${Config.imageUrl}testimonials/${item.video}`}
                                                    controlsList="nodownload noremoteplayback"
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    controls
                                                    className="Testimonial-video w-100 h-auto"
                                                ></video>
                                            ) : (
                                                <img
                                                    src={NoVideoFound}
                                                    alt="No Video Found"
                                                    className="w-75 h-auto object-fit-cover"
                                                />
                                            )}
                                        </div>
                                        <div className="col-md-6 order-md-1 bg-light-green mb-0 p-3">
                                            <div className="d-flex gap-4 align-items-center">
                                                <img src={`${Config.imageUrl}testimonials/${item?.image}`} className="testimonials-image" />
                                                <div>
                                                    <h2 className="text-green">{item.name}</h2>
                                                    <h5>{item.designation} , {item.company_name}</h5>
                                                </div>
                                            </div>
                                            <p className="Testimonial-des mt-3" dangerouslySetInnerHTML={{ __html: item.comment }}></p>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Testimonials;
