import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useLocation } from "react-router";
// import { downloadData } from "./data";
import axios from "axios";
import swal from "sweetalert";

// swarnim Qr's
// import swarnimQr1 from "../assets/Images/swarnim-I/swarnim1.jpg";
// import swarnimQr2 from "../assets/Images/swarnim-II/swarnim2.jpg";
import { Config } from "../Config";
import Loader from "./Loader";
import Footer from "./Footer";
import NotFound from "./NotFound";

export const toCommaSeprated = (value) => {
  const numStr = value.toString();

  if (numStr.includes('.')) {
    return numStr;
  }

  const len = numStr.length;

  if (len <= 3) {
    return numStr;
  } else if (len <= 5) {
    return numStr.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  } else {
    const firstPart = numStr.slice(0, len - 3);
    const lastPart = numStr.slice(len - 3);
    return firstPart.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastPart;
  }
};

const RegisterForm = () => {

  const location = useLocation();
  const drop_sizes = location?.state?.sizes;
  const projectname = location?.state?.project;
  const selectedSize = location?.state?.size || '';

  useEffect(() => {
    if (location.state && location.state.project) {
      localStorage.setItem('projectN', projectname);
    }
  }, [location.state]);

  const [applicationName, setApplicationName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [refrence, setRefrence] = useState("");
  const [aadharCard, setAadharCard] = useState("");
  const [panCard, setPanCard] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");
  const [quota, setQuota] = useState("");
  const [size, setSize] = useState('');
  const [stateDrp, setStateDrp] = useState([]);
  const [cityDrp, setCityDrp] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [size_drop, setSizeDrop] = useState([]);

  const [loading, setLoading] = useState(false);

  const [terms, setTerms] = useState(false);

  const [errors, setErrors] = useState({
    applicationName: "",
    fatherName: "",
    dateOfBirth: "",
    phoneNumber: "",
    emailAddress: "",
    aadharCard: "",
    panCard: "",
    city: "",
    state: "",
    pincode: "",
    address: "",
    quota: "",
    size: "",
    terms: "",
  });

  const [isupcoming, setIsUpcoming] = useState(false);
  const [slug, setSlug] = useState("");

  useEffect(() => {
    axios.get(`${Config.apiUrl}/project_info`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const data = response.data.project_page;

        const filteredData = data.project_info.filter(item => item.project_name === projectname);

        if (filteredData.length > 0 && filteredData[0].status === 2) {
          setIsUpcoming(true);
        } else {
          setIsUpcoming(false);
        }

      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    setSize(selectedSize);
  }, [selectedSize]);

  // window close functionality =========================================
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Your data will be lost if you don\'t save it!'; // Necessary for Chrome
    };

    // Assign event listener directly to window.onbeforeunload
    window.onbeforeunload = handleBeforeUnload;

    // Cleanup function to remove the event listener
    return () => {
      window.onbeforeunload = null; // Remove event listener
    };
  }, []);

  useEffect(() => {
    axios.get(`${Config.apiUrl}/state`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {

        const data = response.data.state;
        const filteredOptions = data.filter(option => option.country_id === 1);
        setStateDrp(filteredOptions);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);

      });
  }, []);

  useEffect(() => {
    if (selectedState) {

      const formData = new FormData();
      formData.append("state_id", selectedState);

      axios.post(`${Config.apiUrl}/city`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          const data = response.data;
          setCityDrp(data.city);
        })
        .catch(error => {
          console.error('There was a problem fetching the city data:', error);
        });
    }
  }, [selectedState]);

  const [size_payment, setSizePayment] = useState("");
  const [selected_payment, setSelectedPayment] = useState("");

  // const payLinks = [
  //   "https://smartpay.easebuzz.in/101948/SwarnimI",
  //   "https://smartpay.easebuzz.in/102223/SwarnimII"
  // ];

  // const QrCodes = [swarnimQr1, swarnimQr2]


  // validations for form
  const handleValidation = () => {
    const newErrors = {};

    if (!applicationName) {
      newErrors.applicationName = "Applicant Name is required";
    }

    if (!fatherName) {
      newErrors.fatherName = "Father Or Husband Name is required";
    }

    if (!dateOfBirth) {
      newErrors.dateOfBirth = "DOB is required";
    }

    if (!aadharCard) {
      newErrors.aadharCard = "Aadhar no. is required";
    } else if (aadharCard.length !== 12) {
      newErrors.aadharCard = "Aadhar no. contains 12 digits";
    }

    if (!panCard) {
      newErrors.panCard = "Pan no. is required";
    } else if (!(/^[A-Z]{5}\d{4}[A-Z]{1}$/).test(panCard)) {
      newErrors.panCard = "Please Enter Valid Pan Number";
    }

    if (!city) {
      newErrors.city = "City is required";
    }

    if (!state) {
      newErrors.state = "State is required";
    }

    if (!pincode) {
      newErrors.pincode = "Pincode is required";
    }

    if (!quota) {
      newErrors.quota = "Quota is required";
    }

    if (!size) {
      newErrors.size = "Size is required";
    }

    if (!refrence) {
      newErrors.refrence = "Refrence By is required";
    }

    if (!address) {
      newErrors.address = "Address is required";
    }

    if (!emailAddress) {
      newErrors.emailAddress = "Email is required";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)
    ) {
      newErrors.emailAddress = "Invalid email format";
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (phoneNumber.length > 10 || phoneNumber.length < 10) {
      newErrors.phoneNumber = "Phone number must be of 10 digits";
    }

    if (!terms) {
      newErrors.terms = "Please Check to agree terms and conditions";
    }

    // if (!size_payment) {
    //   console.log("Size Payment Not selected");
    // }

    //console.log("Size, Payment",size_payment,selected_payment);

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // ==========================

  const [payment_link, setPaymentLink] = useState('')
  const [qr_image, setQrImage] = useState('')
  const [bankDetails, setBankDetails] = useState([]);


  const handleSizeChange = (e) => {
    const selectedSize = e.target.value;
    const sizePart = selectedSize.split(' ')[0];
    // console.log(e.target.value)
    setSizePayment(sizePart);
    setSize(selectedSize);
    //console.log("size_payment", sizePart);
    //console.log("selectedSize", selectedSize);

    const sizeParts = selectedSize.split(' ');
    const lastPart = sizeParts[sizeParts.length - 1];
    //console.log("Last part2", lastPart);

    setSelectedPayment(lastPart);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("application_name", applicationName);
        formData.append("fname", fatherName);
        formData.append("dob", dateOfBirth);
        formData.append("phone", phoneNumber);
        formData.append("email", emailAddress);
        formData.append("aadhar", aadharCard);
        formData.append("pan", panCard);
        formData.append("city", city);
        formData.append("state", state);
        formData.append("pincode", pincode);
        formData.append("address", address);
        formData.append("quota", quota);
        formData.append("size", size);
        formData.append("refrence_by", refrence);
        formData.append("count", localStorage.getItem('projectN'));
        formData.append("size_payment", size_payment);
        formData.append("project_sort", slug);
        formData.append("main_payment",selected_payment);

        //console.log("FormData", formData);

        const res = await axios.post(
          `${Config.apiUrl}/register`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.data.message === "Message Succesfully sent...") {
          //console.log("Response Data",res.data);
          swal("", "Your Application has been sent successfully!", "success").then(() => {
            window.location.href = payment_link;
          });
          setApplicationName("");
          setFatherName("");
          setDateOfBirth("");
          setPhoneNumber("");
          setEmailAddress("");
          setRefrence("");
          setAadharCard("");
          setPanCard("");
          setAddress("");
          setState("");
          setCity("");
          setPincode("");
          setQuota("");
          setSize("");
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        swal("", "Something Went Wrong", "error");
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    // Get the value of the key pressed
    const keyValue = e.key;
    // Check if the key pressed is a numeric character or a control key (like backspace)
    if (!/\d/.test(keyValue) && keyValue !== "Backspace" && keyValue !== "Delete" && keyValue !== "ArrowLeft" && keyValue !== "ArrowRight" && keyValue !== "Tab") {
      e.preventDefault(); // Prevent the default action (typing the character)
    }
  };

  const [back_img, setBackImage] = useState('')

  useEffect(() => {
    setLoading(true)
    axios.get(`${Config.apiUrl}/homepage`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Content-Disposition': 'attachment'
        }
      })
      .then(response => {
        const sortedArray = response?.data?.home_page?.gallery_section?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setBackImage(sortedArray?.slice(0, 1)[0]?.image)
        setLoading(false);
        const link = response?.data?.home_page?.project_info?.filter((obj) => obj.project_name === localStorage.getItem('projectN'))
        setPaymentLink(link[0]?.register_link)

        setQrImage(response?.data?.home_page?.qrcode.filter(item => item.project_name === localStorage.getItem('projectN'))[0]?.image)

        setBankDetails(response?.data?.home_page?.qrcode.filter(item => item.project_name === localStorage.getItem('projectN'))[0]?.bank_details)


      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        setLoading(false);
      })
  }, [])

  useEffect(() => {
    axios.get(`${Config.apiUrl}/project_info`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const data = response.data.project_page;

        const priceList = data.price_list1 || [];

        const filteredData = priceList.filter(p => {
          return p.project_name === localStorage.getItem('projectN');
        });

        const mappedData = filteredData.flatMap(p => {
          let prices = p.price;

          if (typeof prices === 'string') {
            try {
              prices = JSON.parse(prices);
            } catch (e) {
              prices = prices.split(';').map(priceString => priceString.split(','));
            }
          }

          //console.log("Parsed Prices:", prices);

          if (!Array.isArray(prices)) {
            prices = [];
          } else {
            prices = prices.map(priceString => {
              if (typeof priceString === 'string') {
                return priceString.split(',');
              }
              return priceString;
            });
          }

          //console.log("Processed Prices for project:", prices);

          return prices.flatMap(subArray => 
            subArray.map(priceRow => {
                const values = priceRow.split(","); // Convert string into an array
                const size = values[0] || '';  // Get 0th index
                const registrationAmount = values[2] || '';  // Get 2nd index
            
                return {
                size: size,
                title: `${size} Sq ft, Amount ${registrationAmount}`,
                };
            })
          );
        });

        setSizeDrop(mappedData);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (size_drop.length > 0 && selectedSize) {
      setSize(selectedSize);
      const sizePart1 = selectedSize.split(' ')[0];
      setSizePayment(sizePart1);
      //console.log("Size", size_payment);

      const sizeParts = selectedSize.split(' ');
      const lastPart = sizeParts[sizeParts.length - 1];
      //console.log("Last part", lastPart);

      setSelectedPayment(lastPart);

    }
  }, [size_drop, selectedSize]);

  if (isupcoming) {
    <NotFound />
  }

  useEffect(() => {
    //const proj = "Signature Global Signum Plaza4";

    const proj = localStorage.getItem('projectN');

    const firstLetters = proj
      .split(" ")
      .map(word => {
        const numericPart = word.match(/\d+/);
        const alphabeticPart = word.match(/[A-Za-z]/);
        const firstLetter = alphabeticPart ? alphabeticPart[0] : '';
        if (numericPart) {
          return firstLetter + numericPart[0];
        }
        return firstLetter;
      })
      .join("")
      .toUpperCase();

    setSlug(firstLetters);
  }, []);

  // console.log("setSlug",slug);

  return (
    <>
      {loading && <Loader />}
      <Navbar />

      <section className="py-5 bg-contact" style={{ backgroundImage: `url(${Config.imageUrl}/sliders/${back_img})` }}>
        {/* style={{ backgroundImage:`url(${Config.imageUrl}/sliders/${back_img})` }} */}
        <div className="container-box py-md-4">
          <form onSubmit={handleSubmit}>
            <div className="row justify-content-around">
              <div className="col-md-8 bg-white rounded-4 py-5 px-sm-5 px-4">
                <h4
                  className="text-green mb-4 lh-sm"
                  style={{ fontWeight: "600" }}
                >
                  {" "}
                  Registration for Deen Dayal Jan Awas Yojna under the Housing Policy 2016, Government of Haryana
                </h4>

                <div className="line mb-4"></div>
                <div className="row ">
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Applicant Name <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={applicationName}
                        onChange={(e) => setApplicationName(e.target.value)}
                        placeholder="Enter Applicant Name"
                        className="form-field border border-2"
                      />
                      {errors.applicationName && (
                        <span className="text-danger">
                          {errors.applicationName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Applicant Father/Husband Name <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={fatherName}
                        onChange={(e) => setFatherName(e.target.value)}
                        placeholder="Enter Applicant Father/Husband Name"
                        className="form-field border border-2"
                      />
                      {errors.fatherName && (
                        <span className="text-danger">{errors.fatherName}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Date Of Birth <span className="text-red">*</span>
                      </label>
                      <input
                        type="date"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        className="form-field border border-2"
                      />
                      {errors.dateOfBirth && (
                        <span className="text-danger">
                          {errors.dateOfBirth}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Phone Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={phoneNumber}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter Phone Number"
                        className="form-field border border-2"
                      />
                      {errors.phoneNumber && (
                        <span className="text-danger">
                          {errors.phoneNumber}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Email Address <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        placeholder="Enter Email Address"
                        className="form-field border border-2"
                      />
                      {errors.emailAddress && (
                        <span className="text-danger">
                          {errors.emailAddress}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Reference By <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={refrence}
                        onChange={(e) => setRefrence(e.target.value)}
                        placeholder="Reference By"
                        className="form-field border border-2"
                      />
                      {errors.refrence && (
                        <span className="text-danger">
                          {errors.refrence}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Aadhar Card Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={aadharCard}
                        onChange={(e) => setAadharCard(e.target.value)}
                        placeholder="Enter Aadhar Card Number"
                        onKeyPress={handleKeyPress}
                        className="form-field border border-2"
                      />
                      {errors.aadharCard && (
                        <span className="text-danger">{errors.aadharCard}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Pan Card Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={panCard}
                        onChange={(e) => setPanCard(e.target.value)}
                        placeholder="Enter Pan Card Number"
                        className="form-field border border-2"
                      />
                      {errors.panCard && (
                        <span className="text-danger">{errors.panCard}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Address <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Enter Address"
                        className="form-field border border-2"
                      />
                      {errors.address && (
                        <span className="text-danger">{errors.address}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        State <span className="text-red">*</span>
                      </label>
                      <select
                        value={selectedState}
                        onChange={(e) => {
                          const selectedStateName = e.target.options[e.target.selectedIndex].text;
                          setState(selectedStateName);
                          setSelectedState(e.target.value);
                        }}
                        className="form-select border border-2"
                      >
                        <option value="">Select State</option>
                        {stateDrp.map((option, index) => (
                          <option key={index} value={option.id}>{option.name}</option>
                        ))}
                      </select>
                      {errors.state && (
                        <span className="text-danger">{errors.state}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        City <span className="text-red">*</span>
                      </label>
                      <select
                        value={selectedCity}
                        onChange={(e) => {
                          const selectedStateName = e.target.options[e.target.selectedIndex].text;
                          setCity(selectedStateName);
                          setSelectedCity(e.target.value);
                        }}
                        className="form-select border border-2"
                      >
                        <option value="">Select City</option>
                        {cityDrp.map((option, index) => (
                          <option key={index} value={option.id}>{option.name}</option>
                        ))}
                      </select>
                      {errors.city && (
                        <span className="text-danger">{errors.city}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Pin code <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        placeholder="Enter Pin code"
                        onKeyPress={handleKeyPress}
                        className="form-field border border-2"
                      />
                      {errors.pincode && (
                        <span className="text-danger">{errors.pincode}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Quota <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-field bg-white border border-2"
                        style={{ height: 48 }}
                        value={quota}
                        onChange={(e) => setQuota(e.target.value)}
                      >
                        <option value="">-- Select Quota --</option>
                        <option value="Govt Employee Quota">
                          Govt Employee Quota
                        </option>
                        <option value="Female Quota">Female Quota</option>
                        <option value="General Quota">General Quota</option>
                        <option value="Management Quota">
                          Management Quota
                        </option>
                      </select>
                      {errors.quota && (
                        <span className="text-danger">{errors.quota}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Select Size <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-field bg-white border border-2"
                        style={{ height: 48 }}
                        value={size}
                        onChange={handleSizeChange}
                      >
                        <option value="">-- Select Size --</option>
                        {size_drop?.map(
                          (size) => {
                            return (
                              <option key={size.size} value={size.title}>
                                {size.title}
                              </option>
                            );
                          }
                        )}
                      </select>
                      {errors.size && (
                        <span className="text-danger">{errors.size}</span>
                      )}
                    </div>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="terms"
                      onChange={(e) => setTerms(e.target.checked)}
                    />
                    <label
                      htmlFor="terms"
                      className="text-dark ms-3 "
                      style={{ fontSize: "14px" }}
                    >
                      By clicking checkbox, you agree to the terms and
                      conditions set out by this site. <span className="text-red">*</span>
                    </label>
                    {errors.terms && (
                      <p className="text-danger text-big">{errors.terms}</p>
                    )}
                  </div>

                  <div className="mt-4">
                    <button className="button">
                      {loading ? "Submitting data ..." : "Submit & Pay"}
                    </button>
                  </div>
                </div>
              </div>



              <div className="col-md-3 col-8 mt-md-0 mt-5">
                <img
                  src={`${Config.imageUrl}/sliders/${qr_image}`}
                  alt="qr code"
                  className="w-100 h-auto qr-image mb-5"
                />
                <div className='mb-4'>
                  <h6 className={`px-4 py-3 rounded text-white text-center listbox-heading bg-green mb-2`}>
                    Bank Details
                  </h6>

                  <div className="mt-1 px-3 py-3 bg-light-green rounded-4 listbox-content">
                    <div>
                      <ul className="custom-list">
                        <div dangerouslySetInnerHTML={{ __html: bankDetails }} />
                      </ul>
                    </div>
                  </div>



                </div>
                {/* <div className="text-center mt-4 bg-light-green rounded-4 px-3 py-3">
                  <h4 className="text-green">Bank Details</h4>
                  <div dangerouslySetInnerHTML={{ __html: bankDetails }} style={{ textAlign: 'left', marginLeft: '20px' }} />
                </div> */}
              </div>

            </div>
          </form>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default RegisterForm;
