// import React from 'react'
// import { Link } from 'react-router-dom'
// import { Config } from '../Config';
// import swal from 'sweetalert';

// const CTASection = ({ data }) => {

//     const cta_section = data?.ctaSection;
//     const products = cta_section?.projects?.filter(p => p.status === 1)

//     return (
//         <section className='py-5 bg-cta'
//             style={{
//                 backgroundImage: `linear-gradient(rgba(34, 34, 34, 0.7), rgba(34, 34, 34, 0.7)),url(${Config.imageUrl}/${cta_section.cta_bg})`,
//                 backgroundPosition: "center"
//             }}>
//             <div className="container-box py-md-3">

//                 <div className={`text-center ${products?.length <= 2 && 'd-lg-flex'} cta-btns  align-items-center justify-content-between`}>
//                     {
//                         products?.length === 0 ?
//                             <>
//                                 <h2 className='text-white display-6 mb-0' style={{ fontWeight: "600" }}> {cta_section.cta_section_text} </h2>
//                                 <button onClick={() => swal("", "Sorry! Registration is closed!", "error")} className="button text-decoration-none bg-red">
//                                     Registration Closed
//                                 </button>

//                             </> :
//                             <>
//                                 <h2 className='text-white display-6 mb-0' style={{ fontWeight: "600" }}> {cta_section.cta_section_text}  </h2>

//                                 <div className='d-md-flex flex-wrap align-items-center justify-content-center'>
//                                     {
//                                         products?.reverse()?.map((item, itemIndex) => (
//                                             <div key={itemIndex} className={`${products?.length > 2 ? 'mt-5' : 'mt-md-0 mt-5'}  mx-lg-5 mx-4`}>
//                                                 <h3 className='mb-4 text-white '>{item.project_name}</h3>
//                                                 <Link to={`${Config.appUrl}register`}
//                                                     onClick={() => {
//                                                         localStorage.setItem('projectName', item.project_name)
//                                                     }}
//                                                     state={{
//                                                         index: itemIndex,
//                                                         project: item.project_name,
//                                                         //sizes: drop_sizes
//                                                     }}>
//                                                     <button className="button text-decoration-none">
//                                                         Registration Open
//                                                     </button>
//                                                 </Link>
//                                             </div>
//                                         ))
//                                     }

//                                 </div>

//                             </>
//                     }

//                 </div>
//             </div>
//         </section>
//     )
// }

// export default CTASection

import React from 'react';
import { Link } from 'react-router-dom';
import { Config } from '../Config';
import swal from 'sweetalert';
import Slider from 'react-slick';

const CTASection = ({ data }) => {

    const cta_section = data?.ctaSection;
    const products = cta_section?.projects?.filter(p => p.status === 1);

    // Slick Slider settings
    const sliderSettings = {
        dots: true,
        infinite: products?.length > 3 ? true : false,
        speed: 500,
        autoplay : true,
        autoplaySpeed : 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        mobileFirst: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                }
            }
        ]
    };

    return (
        <section className='py-3 bg-cta'
            style={{
                backgroundImage: `linear-gradient(rgba(34, 34, 34, 0.7), rgba(34, 34, 34, 0.7)),url(${Config.imageUrl}/${cta_section.cta_bg})`,
                backgroundPosition: "center"
            }}>
            <div className="container-box py-md-3">

                <div className={`text-center cta-btns align-items-center justify-content-between`}>
                    {
                        products?.length === 0 ?
                            <>
                                <h2 className='text-white display-6 mb-0' style={{ fontWeight: "600" }}> Registration Closed </h2>
                                <button onClick={() => swal("", "Sorry! Registration is closed!", "error")} className="button text-decoration-none mt-4 bg-red">
                                    Registration Closed
                                </button>
                            </>
                            :
                            <>
                                <h2 className='text-white display-6 mb-0' style={{ fontWeight: "600" }}> {cta_section.cta_section_text} </h2>

                                {/* Slider container */}
                                <div className="cta-slider pb-3 mt-4">
                                    <Slider {...sliderSettings}>
                                        {
                                            products?.reverse()?.map((item, itemIndex) => (
                                                <div key={itemIndex} className="cta-slider-item">
                                                    <h3 className='mb-2 text-white'>{item.project_name}</h3>
                                                    <Link to={`${Config.appUrl}register`}
                                                        onClick={() => {
                                                            localStorage.setItem('projectName', item.project_name);
                                                        }}
                                                        state={{
                                                            index: itemIndex,
                                                            project: item.project_name,
                                                        }}>
                                                        <button className="button text-decoration-none">
                                                            Registration Open
                                                        </button>
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </div>
                            </>
                    }
                </div>
            </div>
        </section>
    );
}

export default CTASection;
