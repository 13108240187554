import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import axios from 'axios';
import { Config } from '../Config';
import Loader from './Loader';

import "../assets/css/PressRelease.css"
import { useParams } from 'react-router';
import moment from 'moment';

const PressRelease = () => {

    const [pressData, setPressData] = useState(null)
    const [loading, setLoading] = useState(false)

    const { slug } = useParams()

    useEffect(() => {
        const getPressData = async () => {
            setLoading(true)
            try {
                const response = await axios.get(`${Config.apiUrl}/pressrelease/${slug}`)
                if (response.data.success)
                    setPressData(response.data.press_release)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        getPressData()
    }, [])


    return (
        <>
            {loading && <Loader />}
            <Navbar />

            <section className="py-4 pt-2 bg-light-green">
                <div className="container-box py-md-4">
                    <div className="press-release-page">
                        <div className="content-wrapper">
                            <img
                                src={`${Config.imageUrl}/${pressData?.image}`} // Replace with your banner image URL
                                alt="Press Release Banner"
                                className="press-banner object-fit-contain bg-light"
                                style={{height: "500px"}}
                            />
                            <div>
                                 <i className='bi bi-calendar me-2'></i> {moment(pressData?.created_at).format("DD MMMM, YYYY")}
                            </div>
                            <h1 className="press-title text-green mt-4">{pressData?.title}</h1>

                            <div className="press-content mt-4">

                                <div dangerouslySetInnerHTML={{ __html: pressData?.description }} />

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default PressRelease