import { Helmet } from "react-helmet";
import Loader from "./Loader"
import Navbar from "./Navbar"
import Footer from "./Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { Config } from "../Config";

const sectionStyle = {
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  color: "white"
};

const RefundPolicy = () => {

    const [loading , setLoading] = useState([]);
    const [titles, settitles] = useState({});
    const [Data , setData] = useState();

    useEffect(() => {
        setLoading(true)
        axios.get(`${Config.apiUrl}/policy`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            setData(response?.data?.refund_policy);
            settitles(response?.data?.titles?.filter(item => item?.id === 5))
          })
          .catch(error => {
            console.error('There was a problem fetching the city data:', error);
          })
          .finally(() => {
            setLoading(false);
          })
    
    }, []);

    return(
        <div style={{backgroundColor:"#F4FAF0"}}>
            <Helmet>
                <title>Affordable Jan Awas Yojna Plots in Haryana, Farrukhnagar, Dwarka Expressway, Rohtak</title>
                <meta name="description" content="Explore affordable Deen Dayal plots under Jan Awas Yojna in Haryana. Search for 'Deen Dayal Plots Near Me' to find the best options." />
                <meta name="keywords" content="deen dayal jan awas yojna, deen dayal awas yojna plots, deen dayal jan awas yojna plots,deen dayal affordable plots,
                    deen dayal plots near me,deen dayal plots,deen dayal awas yojna haryana,Deen Dayal jan Awas yojna plot in Farrukhnagar,Deen Dayal jan Awas yojna plot in Dwarka Expressway,
                    Deen Dayal plot in Rohtak,Deen Dayal jan Awas yojna plot in Rohtak,jan awas plots near me,DDJAY PLOTS" />
            </Helmet>
            {
                loading && <Loader />
            }
            <Navbar />

            <div className="text-center py-5" style={{
                ...sectionStyle,
                backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${Config.imageUrl}${titles[0]?.bg})`,
            }}>
                <div className="container-box py-4">
                    <div className="Testimonials-Banner">
                        <h1>{titles[0]?.title}</h1>
                        <h5>{titles[0]?.subtitle}</h5>
                    </div>
                </div>
            </div>

            <section className="py-5">
                <div className="container-box py-md-3">
                    <div className="px-xl-4">
                      {/* <div className="text-center">
                        <h1 className=" text-green fw-bold">{Data?.title}</h1>
                        <div className="line mx-auto my-4"></div>
                      </div> */}
                      <p dangerouslySetInnerHTML={{__html : Data?.description}}></p>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    )

}

export default RefundPolicy;