import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import axios from 'axios';
import { Config } from '../Config';
import Loader from './Loader';

import "../assets/css/PressRelease.css";
import moment from 'moment';
import { Link } from 'react-router-dom';

const sectionStyle = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "white"
};

const PressReleaseList = () => {

    const [pressData, setPressData] = useState([])
    const [loading, setLoading] = useState(false)
    const [titles, settitles] = useState({});

    useEffect(() => {
        const getPressData = async () => {
            setLoading(true)
            try {
                const response = await axios.get(`${Config.apiUrl}/pressrelease`)
                if (response.data.success)
                    setPressData(response.data.press_release)
                    settitles(response?.data?.titles)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        getPressData()
    }, [])

    return (
        <>
            {loading && <Loader />}
            <Navbar />

            <div className="text-center py-5" style={{
                    ...sectionStyle,
                    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${Config.imageUrl}${titles[0]?.bg})`,
                }}>
                <div className="container-box py-4">
                    <div className="Testimonials-Banner">
                        <h1>{titles[0]?.title}</h1>
                        <h5>{titles[0]?.subtitle}</h5>
                    </div>
                </div>
            </div>

            <section className="pb-5 pt-4 bg-light-green">
                <div className="container-box py-md-4">
                    <div  >
                        <div className="row">
                            {
                                pressData.map((press) => {
                                    return (
                                        <div key={press?.id} className="col-md-4">
                                            <div className="bg-white p-4 rounded-3 shadow-sm" style={{minHeight: "422px"}}>
                                                <Link to={`${Config.appUrl}news-details/${press?.slug}`} className='text-decoration-none'>
                                                    <img
                                                        src={`${Config.imageUrl}/${press?.image}`}
                                                        alt="Press Release Banner"
                                                        className="w-100 object-fit-cover"
                                                        style={{ height: "250px" }}
                                                    />

                                                    <div className="press-content mt-4">
                                                        <div>
                                                            <h4 className=" text-green mt-md-0 mt-4 mb-3">
                                                                {press?.title.slice(0, 60)}..
                                                            </h4>
                                                        </div>
                                                        <div>
                                                            <h6 className="fw-normal  mt-md-0 mt-4">
                                                                <i className='bi bi-calendar me-2'></i>{moment(press?.created_at).format("DD MMMM, YYYY")}</h6>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default PressReleaseList