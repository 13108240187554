import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../assets/css/Home.css";
import Marquee from "react-fast-marquee";
import CTASection from "./CTASection";
import Loader from "../Components/Loader";
import axios from "axios";
import { Config } from "../Config";
import MainHomeSection from "./MainHomeSection";
import "aos/dist/aos.css";
import Aos from "aos";
import Footer from "./Footer";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import MakeAppointment from "./Calendly/MakeAppointment";
import NewMainHomeSection from "./NewHomeSection";
import image1 from "../assets/Images/tathastu.jpg"
import image2 from "../assets/Images/6603d5d1e0739.jpg"
import image3 from "../assets/Images/6604110aec8ea.jpg"
import Slider from "react-slick";
import moment from "moment";
import DOMPurify from 'dompurify';

const Index = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [closedprojects,setclosedprojects] = useState([])


  const settings = {
    dots: true,
    infinite: closedprojects?.length > 3 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    autoplay : true,
    autoplaySpeed : 2000,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
    appendDots: (dots) => (
      <div style={{ padding: "10px" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "6px",
          height: "6px",
          backgroundColor: "gray",
          borderRadius: "50%",
          display: "inline-block",
          margin: "0 4px",
        }}
      ></div>
    ),
  };

  const [blogData, setBlogData] = useState([]);
  const settings1 = {
    dots: true,
    infinite: blogData?.length >= 3 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: true,
    autoplay : true,
    autoplaySpeed : 2000,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
    appendDots: (dots) => (
      <div style={{ padding: "10px" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "6px",
          height: "6px",
          backgroundColor: "gray",
          borderRadius: "50%",
          display: "inline-block",
          margin: "0 4px",
        }}
      ></div>
    ),
  };

  useEffect(() => {
    Aos.init({
      once: true,
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, [])

  const [loading, setLoading] = useState(true);
  const [bannerSection, setBannerSection] = useState({});
  const [gallerySection, setGallerySection] = useState([]);
  const [ctaSection, setctaSection] = useState({});
  const [cta, setCta] = useState([]);
  const [chooseSection, setChooseSection] = useState({});
  const [choose_points, setchoose_points] = useState([]);
  const [investSection, setInvestSection] = useState([]);
  const [invest_points, setinvest_points] = useState([]);
  const [partnershipSection, setPartnershipSection] = useState({});
  const [partnership_points, setpartnership_points] = useState([]);
  const [mainHeaderData, setMainHeaderData] = useState(null);
  const [benefitSection, setBenefitSection] = useState({});
  const [benifit_points, setbenifit_points] = useState([]);

  const Navigate = useNavigate();

  const [downloadData, setDownLoadData] = useState([]);
  const [downloadBg, setDownloadBg] = useState([]);

  const [calendlyBanner, setCalendlyBanner] = useState(null);

  const [Shifts , setShifts] = useState([]);
  const [Dates , setDates] = useState({});


  useEffect(() => {
    axios.get(`${Config.apiUrl}/homepage`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json',
          'Content-Disposition': 'attachment'
        }
      })
      .then(response => {

        const data = response.data.home_page;

        setShifts(data?.shifts)
        setDates(data?.dates);

        // console.log('home_page', data)
        setBannerSection(data.banner_section);
        setGallerySection(data.gallery_section);
        setctaSection({ ...data.cta_section, projects: data.project_info });
        setCta(data.cta);

        setChooseSection(data.choose_section);
        setchoose_points(
          getFinalPoints(data.choose_section.choose_description)
        )

        setInvestSection(data.invest_section);
        setinvest_points(
          getFinalPoints(data.invest_section.invest_description)
        )

        setBenefitSection(data.benefits_section);
        setbenifit_points(
          getFinalPoints(data.benefits_section.benefits_description)
        )

        setPartnershipSection(data.partnership_section);

        setDownloadBg(data.download_section);

        // Extracting the last <p> tag with its content
        const inputString = data.partnership_section.partnership_description
        var lastIndex = inputString.lastIndexOf('<p>');
        var endIndex = inputString.indexOf('</p>', lastIndex);
        var extractedString = inputString.substring(lastIndex, endIndex + 4); // Adding 4 to include '</p>'
        // console.log('extractedString : ', extractedString);

        setpartnership_points(
          getFinalPoints(inputString)
        )

        setCalendlyBanner(data.calendly)

        // console.log('inputString', inputString.replace(extractedString, ""))

        setLoading(false);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        setLoading(false);
      });
  }, []);
  // console.log("bannerSection", bannerSection);

  const getFinalPoints = (content) => {
    return (
      content
        .split('<li>').map(item => item.replace(/<\/?[^>]+(>|$)/g, "").replaceAll('&nbsp;', ' '))
        .filter(item => item != "")
    )
  }
  
  const fetchData = () => {
    axios.get(`${Config.apiUrl}/project_info`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setMainHeaderData(response.data.project_page)

        // calculating recent added 2 projects
        const currentDate = new Date();
        // Function to calculate the difference between two dates
        const dateDiff = (date1, date2) => Math.abs(date1 - date2);

        // Calculate differences and sort
        const sortedDates =
          response.data.project_page?.project_info
            .filter(pr => pr.status === 1)
            .map(project_data => {
              const date = new Date(project_data.updated_at);
              return { date, difference: dateDiff(date, currentDate), pname: project_data.project_name  , id: project_data.id};
            }).sort((a, b) => a.difference - b.difference);

        const projects_download_data = sortedDates?.map((project) => ({
          heading: {
            text: `Download ( ${project.pname} )`,
            bgcolor: "bg-green",
            id : project?.id
          },
          list: response.data.project_page?.project_download
            .filter(item => item.project_name === project.pname)
            .map(item => ({
              title_sm: item.document_name,
              title_lg: "",
              file: item.download_pdf
            }))
        }))

        setDownLoadData(projects_download_data)
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
      });
  }


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Function to be called every 2 minutes
    const intervalId = setInterval(() => {
        fetchData()
    }, 2 * 60 * 1000); // 2 minutes in milliseconds (2 * 60 * 1000)
  
    // Cleanup function to clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);


  useEffect(() => {
    axios.get(`${Config.apiUrl}/blogs`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const responseData = response.data.contact_page.blog;
        //console.log("responseData",responseData);
        const mappedData = responseData.map(blog => ({
          id: blog.id,
          date: formatDate(blog.created_at),
          title: blog.title?.slice(0, 40),
          content: blog.content?.replace(/<[^>]+>/g, '').length > 100 ?
            blog.content?.replace(/<[^>]+>/g, '').substring(0, 100) + '...' : blog.content,
          imgSrc: blog.main_image,
          slug: blog.slug,
          tags: blog.tags ? blog.tags.split(',').map(tag => `#${tag.trim()}\u00A0\u00A0`).join(' ') : [],
        }));
        const lastThreeBlogs = mappedData;

        setBlogData(lastThreeBlogs);
      })
      .catch(error => {
        console.error('There was a problem fetching the city data:', error);
      });

  }, []);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const [ongoingProjects,setongoingProjects] = useState([])

  useEffect(() => {
    setongoingProjects(
      mainHeaderData?.project_info
        .filter(pr => pr.status === 1)
        .reverse()
        .map(item => {

          const matchedDownload = mainHeaderData?.downloadData?.find(downItem => downItem?.list.some(subItem => subItem?.id === item?.id));

          return{
          id:item?.id,
          title_sm: "",
          last_date : item?.last_date,
          description  : item?.description,
          startDate : item?.date,
          endDate:  item?.last_date	,
          allotment_date : item?.allotment_date,
          title_lg: item.project_name,
          scheme_info : item?.scheme_info,
          location_info : item?.location_info,
          name: item.project_name,
          project_text2 : item?.project_text2,
          url: `${Config.appUrl}projectdetails/${item.project_name.replaceAll(" ", "-").toLowerCase()}`,
          downloadData: matchedDownload
          };
        })
    )
  },[mainHeaderData])

  useEffect(() => {
    setclosedprojects(
      mainHeaderData?.project_info
        .filter(pr => pr.status === 0)
        .reverse()
        .map(item => {

          const matchedDownload = mainHeaderData?.downloadData?.find(downItem => downItem?.list.some(subItem => subItem?.id === item?.id));

          return{
          id:item?.id,
          title_sm: "",
          last_date : item?.last_date,
          description  : item?.description,
          allotment_date : item?.allotment_date,
          title_lg: item.project_name,
          scheme_info : item?.scheme_info,
          location_info : item?.location_info,
          name: item.project_name,
          project_text2 : item?.project_text2,
          url: `${Config.appUrl}projectdetails/${item.project_name.replaceAll(" ", "-").toLowerCase()}`,
          downloadData: matchedDownload
          };
        })
    )
  },[mainHeaderData])

  const [newsData, setNewsData] = useState([])


  // Function to Change Status when it Closed
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();

      setNewsData(mainHeaderData?.news)
  
      // Iterate through each project in mainHeaderData?.project_info
      mainHeaderData?.project_info?.forEach(async (project) => {
        // If the project is already closed (status 0), skip the API call
        if (project?.status === 0) {
          return;
        }
  
        // Select the appropriate date field based on the project status
        const projectTime =
          project?.status === 1
            ? new Date(project?.last_date).getTime()  // Use 'last_date' for status 1
            : project?.status === 2
            ? new Date(project?.date).getTime()  // Use 'date' for status 2
            : null;  // In case of status other than 1 or 2, we don't check
  
        if (projectTime !== null) {
          const timeDiff = currentTime - projectTime;
  
          // Check if project datetime is less than or equal to the current time
          if (timeDiff >= 0 && (project?.status === 1 || project?.status === 2)) {
            // Make API call to close the project
            try {
              const updatedProject = await closeProject(project?.id, project?.status);
  
              // Update the project status in the local state after the response
              const updatedProjectInfo = mainHeaderData?.project_info?.map((p) =>
                p.id === project?.id ? { ...p, status: updatedProject.status } : p
              );
  
              // Assuming you have a function to update the project state
              setMainHeaderData((prevData) => ({
                ...prevData,
                project_info: updatedProjectInfo,
              }));
            } catch (error) {
              console.error('Failed to close project', error);
            }
          }
        }
      });
    }, 10000); // Update every 10 seconds
  
    return () => clearInterval(interval);
  }, [mainHeaderData]);
  

  const closeProject = async (projectId,status) => {
    // Make your API call here
    try {
      const response = await axios.post(`${Config.apiUrl}/updatestatus`, {
        projectId : projectId,
        status : status
      });
      return response.data;
    } catch (error) {
      console.error('Error closing project:', error);
    }
  };

  const [active,setActive] = useState(0);

  const activeindex = (index) => {
    setActive(index)
  }

  const [Marqueetext, setMarqueetext] = useState("");

  useEffect(() => {
    // Check if ongoingProjects and activeIndex are valid
    if (ongoingProjects && ongoingProjects[active]) {
      const project = ongoingProjects[active];

      // Format the start date, end date, and allotment data
      const startDate = new Date(project.startDate).toLocaleDateString();
      const endDate = new Date(project.endDate).toLocaleDateString();
      const allotmentData = new Date(project.allotment_date).toLocaleDateString();

      // Replace placeholders in the marquee text
      const updatedMarquee = bannerSection?.hero_section_title
        ?.replace('${startdate}', `<span class="text-animated">${startDate}</span>`)
        ?.replace('${lastdate}', `<span class="text-animated">${endDate}</span>`)
        ?.replace('${allotementdata}', `<span class="text-animated">${allotmentData}</span>`);

        const sanitizedMarquee = DOMPurify.sanitize(updatedMarquee);
        setMarqueetext(sanitizedMarquee);
    }
  }, [ongoingProjects, active, bannerSection?.hero_section_title]);


  return (
    <div style={{overflowX:"hidden"}}>
      <Helmet>
        <title>Affordable Jan Awas Yojna Plots in Haryana, Farrukhnagar, Dwarka Expressway, Rohtak</title>
        <meta name="description" content="Explore affordable Deen Dayal plots under Jan Awas Yojna in Haryana. Search for 'Deen Dayal Plots Near Me' to find the best options." />
        <meta name="keywords" content="deen dayal jan awas yojna, deen dayal awas yojna plots, deen dayal jan awas yojna plots,deen dayal affordable plots,
        deen dayal plots near me,deen dayal plots,deen dayal awas yojna haryana,Deen Dayal jan Awas yojna plot in Farrukhnagar,Deen Dayal jan Awas yojna plot in Dwarka Expressway,
        Deen Dayal plot in Rohtak,Deen Dayal jan Awas yojna plot in Rohtak,jan awas plots near me,DDJAY PLOTS" />
      </Helmet>
      {
        loading && <Loader />
      }

      <div>
        <Marquee
          // speed={8} // Speed of the marquee (Optional)
          className="text-green head-line"
        >
          {
            Marqueetext && (
              <div dangerouslySetInnerHTML={{ __html: Marqueetext }} />
            )
          }
          {/* 33% कोटा महिलाओं एवं */}
        </Marquee>
      </div>

      <Navbar />

      {/* <MainHomeSection data={{
        banner_img: bannerSection.hero_bg,
        images: gallerySection,
        mainHeaderData: mainHeaderData,
        downloadData: downloadData,
        banner_section: bannerSection,
        bankName: bannerSection.bank_name
      }} /> */}

      <NewMainHomeSection data={{
        banner_img: bannerSection.hero_bg,
        images: gallerySection,
        mainHeaderData: mainHeaderData,
        downloadData: downloadData,
        banner_section: bannerSection,
        bankName: bannerSection.bank_name
      }}
      activeindex={activeindex}
      
      />

      {/* {
        downloadData.length > 0 &&
        <section className="pb-5 mt-5" >
          <div className=" mx-4">
            <div className=" p-4 bg-contact"
              style={{ backgroundImage: `linear-gradient(#0000006e, #0000006e), url(${Config.imageUrl}/${downloadBg.download_bg})` }}
            >
              {
                downloadData?.map((details) => {
                  return (
                    <div key={details?.heading?.text} className="row pt-3 pb-3">

                      <h5 className="text-white fw-bold mb-4">{details?.heading?.text}</h5>

                      {
                        details?.list?.map((list) => {
                          return (
                            <div key={list.title_sm} className="col-sm-3 mb-3">
                              <div className="card hover-effect" data-aos="flip-up" >
                                <div className="card-body">
                                  <div className="py-1">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="fw-bold m-0">
                                        <a href={`${Config.docUrl}${list.file}`} target="_blank" rel="noreferrer" className="text-decoration-none text-green">
                                          {list.title_sm.slice(0, 22)}..
                                        </a>
                                      </p>
                                      <a href={`${Config.docUrl}${list.file}`} target="_blank" rel="noreferrer" className="badge bg-green"><i className="bi bi-download"></i></a>
                                    </div>
                                    <div className="pt-2 border-bottom border-info"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }

                    </div>
                  )
                })
              }

            </div>
          </div>
        </section>
      } */}

      <section className="py-4 pt-2 mt-5 border-top border-bottom bg-light-green">
        <div className="container-box py-md-4">
          <div className=''>
            <div className="text-center">
              <h2 className="fw-bold text-capitalize  text-green">
                {benefitSection.benefits_title}
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                {benifit_points.slice(0, Math.ceil(benifit_points.length / 2)).map((point, i) => {
                  return (
                    <div className="d-flex benifit_points-left" key={i}>
                      <i className="bi bi-arrow-right me-3"></i>
                      <p className="paragraph mb-0 text-justify">
                        {point}
                      </p>
                    </div>
                  )
                })}
              </div>

              <div className="col-lg-6">
                {benifit_points.slice(Math.ceil(benifit_points.length / 2)).map((point, i) => {
                  return (
                    <div className="d-flex benifit_points-left" key={i}>
                      <i className="bi bi-arrow-right me-3"></i>
                      <p className="paragraph mb-0 text-justify">
                        {point}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>

          </div>
        </div>
      </section>

       <CTASection data={{ ctaSection: ctaSection }} />

      <div className="px-xl-5 py-5 border-bottom">
        <div className="d-flex justify-content-between px-3">
          <h3 className="">Closed Projects</h3>
          <div className="text-center">
            <Link to={`${Config.appUrl}all-projects`} className="btn" style={{border:"1px solid #03a659"}}>View All Closed Projects</Link>
          </div>
        </div>
        <Slider {...settings}>
          {
            closedprojects?.map((item,index) => {
              const projectImage = mainHeaderData?.gallery?.find(image => image?.project_id === item?.id); // Get the image specific to the current project
              const backgroundImage = projectImage?.image || '';
              return(
                <div className="col-xl-4 px-1 px-xl-3 mt-4">
                  <div className=" hover-container "
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(${Config.imageUrl}/gallery/${backgroundImage})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: "cover",
                    }}>
                      <h4 className="text-white mt-3 Main-Title mb-3">{item?.title_lg}</h4>
                      <div className="content text-start">
                          <h3 className="text-white">{item?.title_lg}</h3>
                          <h6 style={{lineHeight:"30px" , height:"200px", overflowY:'scroll' , scrollbarWidth:'none'}} className="text-white me-3 mt-1 text-justify" dangerouslySetInnerHTML={{__html : item?.scheme_info}}></h6>
                          <Link to={item?.url} state={{ index: index, project: item.name }} style={{border:"1px solid white"}} className="btn me-3 mt-4 text-white">
                            View More
                          </Link>
                      </div>
                  </div>
                  {/* <div className="Project-section">
                    <h4 className="text-center fw-bold" style={{height:"40px" ,borderBottom:"1px solid white"}}>{item?.title_lg}</h4>
                    <h6 style={{lineHeight:"30px" , height:"300px", overflowY:'scroll' , scrollbarWidth:'none'}} dangerouslySetInnerHTML={{__html : item?.scheme_info}}></h6>
                    <Link to={item?.url} state={{ index: index, project: item.name }} style={{border:"1px solid white"}} className="btn mt-3 text-white">
                      View More
                    </Link>
                  </div> */}
                </div>
              )
            })
          }
        </Slider>
        
      </div>

      <CTASection data={{ ctaSection: ctaSection }} />

      {/* Invest Section */}
      <section className="py-5 border-bottom bg-light-green">
        <div className="container-box py-md-4">
          <div className="">
            {/* points */}
            <div className="">
              <h2 className="fw-bold text-green text-center text-capitalize">
                {investSection.invest_title}
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>

            <div className="row">

              <div className="col-lg-6">

                {
                  invest_points.slice(0, invest_points?.length / 2)
                    .map((point, i) => {
                      const content = point.split(':')
                      return (
                        <div className="d-flex partnership_points" key={i}>
                          {/* <i className="bi bi-arrow-right me-3"></i> */}

                          <p className="paragraph text-justify">
                            <b> {content[0]} : </b>
                            {content[1]}
                          </p>
                        </div>
                      )
                    })
                }

              </div>

              <div className="col-lg-6">

                {
                  invest_points?.slice(invest_points?.length / 2)
                    .map((point, i) => {
                      const content = point.split(':')
                      return (
                        <div className="d-flex partnership_points" key={i}>
                          {/* <i className="bi bi-arrow-right me-3"></i> */}

                          <p className="paragraph text-justify">
                            <b> {content[0]} : </b>
                            {content[1]}
                          </p>
                        </div>
                      )
                    })
                }

              </div>

            </div>

          </div>
        </div>
      </section>
      {/* End */}

      <CTASection data={{ ctaSection: ctaSection }} />

      {/* Development Section */}
      <section className="py-5 border-bottom bg-light-green">
        <div className="container-box py-md-3">
          <div className="">
            <div className="">
              <h2 className="fw-bold text-green text-center text-capitalize">
                {partnershipSection.partnership_title}
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>
            {/* points */}

            <div>
              <div className="row">

                <div className="col-lg-6">

                  {
                    partnership_points.slice(0, Math.ceil(partnership_points?.length / 2))
                      .map((point, i) => {
                        const content = point.split(':')
                        return (
                          <div className="d-flex partnership_points" key={i}>
                            {/* <i className="bi bi-arrow-right me-3"></i> */}

                            <p className="paragraph text-justify">
                              <b> {content[0]} : </b>
                              {content[1]}
                            </p>
                          </div>
                        )
                      })
                  }

                </div>

                <div className="col-lg-6">

                  {
                    partnership_points?.slice(Math.ceil(partnership_points?.length / 2))
                      .map((point, i) => {
                        const content = point.split(':')
                        return (
                          <div className="d-flex partnership_points" key={i}>
                            {/* <i className="bi bi-arrow-right me-3"></i> */}

                            <p className="paragraph text-justify">
                              <b> {content[0]} : </b>
                              {content[1]}
                            </p>
                          </div>
                        )
                      })
                  }

                </div>
              </div>
            </div>
            <div>
              <h5 className="mt-4">Conclusion:</h5>
              <p className="mt-2">{partnershipSection?.partnership_conclusion}</p>
            </div>
          </div>
        </div>
      </section>
      {/* End */}

      {/* <CTASection data={{ ctaSection: ctaSection }} /> */}
      <MakeAppointment data={calendlyBanner} Shifts={Shifts} Dates={Dates} projectData={ongoingProjects} pricelist={mainHeaderData?.price_list1} />

      <section className="py-5">
        <div className="container-box py-md-3">
          <div className="">
            <div className="d-flex align-items-center justify-content-between">
              <div className="">
                <h2 className="fw-bold text-green text-capitalize">
                  Blogs
                </h2>
                <div className="line mt-4 mx-auto"></div>
              </div>
              <div className="">
                <Link className="button text-decoration-none text-white" to={`${Config.appUrl}blogs`}>View All</Link>
              </div>
            </div>

            <div className="mt-5">
              <div className="row">
              <Slider {...settings1}>
                {blogData?.map((blog) => (
                  <div className="col-md-3 " key={blog.id}>
                    <div className="blog-content1">
                      <div>
                        <img src={`${Config.imageUrl}/blogs/${blog.imgSrc}`} alt={`Blog ${blog.id}`}></img>
                      </div>
                      <div className="blog-section">
                        <h5>{blog.date}</h5>
                        <h4>{blog.title}..</h4>
                        <div className="text-justify blog-desc" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                        {/* <div>{blog.tags}</div> */}
                        <span>
                          <NavLink to={`${Config.appUrl}blogdetail/${blog.slug}`}>Read More <i className="bi bi-chevron-double-right"></i></NavLink>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              </div>
            </div>

          </div>
        </div>
      </section>
      <div className="px-5 py-5 border-bottom">
        <div>
          <h5 className="fw-bold text-animated text-capitalize">
            News Updates
          </h5>
        </div>

        <div className="mt-3">
          <div className="marquee-container">
            <div className="marquee">
              <div className="row">
                {
                  newsData?.length > 0 ? (
                    newsData.map((news) => (
                      <a key={news.id}
                        onClick={(e) => {
                          if (!news.pdf)
                            e.preventDefault()
                        }}
                        href={`${Config.docUrl}${news.pdf}`} target='_blank'
                        className='d-block text-dark text-decoration-none'>
                        <div className="d-flex flex-column">
                          <div className="title-marquee">
                            <i className="bi bi-calendar-date me-3 text-blue"></i>
                            <h4 className="heading-marquee">{moment(news.date).format("DD MMM YYYY")}
                              <span className='text-animated d-inline rounded ms-1 badge lh-sm' style={{ fontSize: "12px" }}> <i className='bi bi-arrow-left fs-6 me-1'></i> Check Now  </span>
                            </h4>
                          </div>

                          <p className="paragraph-marquee">
                            {news.news}
                          </p>
                          <div className="line-marquee"></div>
                        </div>
                      </a>
                    ))
                  ) : (
                    <p>No news available</p>
                  )
                }
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
