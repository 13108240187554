import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "./Components/Index";
import Contact from "./Components/Contact";
// import GoreGao from "./Components/GoreGao";
import RegisterForm from "./Components/RegisterForm";
import Swarnim1 from "./Components/Sectors/Swarnim1";
import AllSectors from "./Components/AllSectors";
import SwarnimFirst from "./Components/Policy/SwarnimFirst";
import { Config } from "./Config";
import AffordablePolicy from "./Components/Policy/AffordablePolicy";
import AboutUs from "./Components/AboutUs";
import Gallery from "./Components/Gallery";
import Refund from "./Components/Refund";
import BlogDetail from "./Components/BlogDetail";
import Blogs from "./Components/Blogs";
import NewsUpdate from "./Components/NewsUpdate";
import PressRelease from "./Components/PressRelease";
import PressReleaseList from "./Components/PressReleaseList";
import RefundPolicy from "./Components/RefundPolicy"
import PrivacyPolicy from "./Components/PrivacyPolicy";
import NewsLetter_UnSubscribe from "./Components/NewsLetter";
import Testimonials from "./Components/Testimonial";
import RefundTestimonials from "./Components/refundTestimonials";
import { Modal, notification } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Loader from "./Components/Loader";

function App() {

  const UseIP = async(ipaddress1) => {
    setLoading(true);
    const formdata = new FormData()
    formdata.append("ip_address" , ipaddress1);
    try{
      const res = await axios.post(`${Config.apiUrl}/ipaddress`, formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          if (res.data?.success === false) {
            setIpLocation(true);
          }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
  }


  const [userIP, setUserIP] = useState("");
  const [IpLocation , setIpLocation] = useState(false);


  const length = 6

  const initialOtp =  Array(length).fill('')
  const [otpValues, setOtpValues] = useState(initialOtp);


    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                // Get IP & Location
                const { data } = await axios.get("https://ipapi.co/json/"); // You can also use "https://ipinfo.io/json"

                setUserIP(data.ip);
                UseIP(data?.ip);
            } catch (error) {
                console.error("Error fetching IP data:", error);
            }
        };

        fetchUserDetails();
  }, []);
  const [Name , SetName] = useState("");
  const [Email , setEmail] = useState("");
  const [Phone , setPhone] = useState("");
  const [Errors , setErrors] = useState("");
  const [Loading,setLoading] = useState(false);
  const [OTPSent , setOTPsent] = useState(false);
  const [seconds, setSeconds] = useState(60);

  const validation1 = () => {
    const newErrors = {};

    if (!Name) {
      newErrors.Name = "Name is required";
    }

    if (!Email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      newErrors.email = "Invalid email format";
    }

    if (!Phone) {
      newErrors.phone = "Phone Number is required";
    } else if (Phone.length > 15 || Phone.length < 7) {
      newErrors.phone = "Invalid Phone Number length";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    if (validation1()) {
      setLoading(true);
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", Name);
        formData.append("phone", Phone);
        formData.append("email", Email);
        formData.append("ip_address",userIP);
        formData.append("type", 1);

        const res = await axios.post(`${Config.apiUrl}/contact`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.status === 200) {
          secureLocalStorage.setItem("User_Otp",res.data.otp);
          setSeconds(20);
          notification.open({
            message: (
              <div className="d-flex align-items-center">
                <div className="pr-20">
                  <i
                    className="bi bi-check2-circle text-success me-2"
                    style={{ fontSize: "20px" }}
                  ></i>
                </div>
                <div className="succes-message">OTP Sent Successfully. </div>
              </div>
            ),
          });
          setOTPsent(true);
        }
        setLoading(false);
      } catch (error) {
        notification.open({
          message: (
            <div className="d-flex align-items-center">
              <div className="pr-20">
                <i
                  className="bi bi-x-circle text-danger me-2"
                  style={{ fontSize: "20px" }}
                ></i>
              </div>
              <div className="">Sorry Something went Wrong. </div>
            </div>
          ),
          description: error?.message,
        });
        setLoading(false);
      }
    }

  };

  const user_info = secureLocalStorage.getItem("User_Otp")

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    const newValues = [...otpValues];
    newValues[index] = value;
    setOtpValues(newValues);

    // Move to the next input when a digit is entered
    if (value !== '' && index < length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const HandleVerify = () => {
    if(Number(otpValues.join('')) === user_info){
      setIpLocation(false);
      secureLocalStorage.removeItem("User_Otp");
      notification.open({
        message: (
          <div className="d-flex align-items-center">
            <div className="pr-20">
              <i
                className="bi bi-check2-circle text-success me-2"
                style={{ fontSize: "20px" }}
              ></i>
            </div>
            <div className="succes-message">Verification Completed</div>
          </div>
        ),
      });
    }else{
      notification.open({
        message: (
          <div className="d-flex align-items-center">
            <div className="pr-20">
              <i
                className="bi bi-x-circle text-success me-2"
                style={{ fontSize: "20px" }}
              ></i>
            </div>
            <div className="succes-message">Please Enter Valid OTP.</div>
          </div>
        ),
      });
    }
  }
  useEffect(() => {
    if (seconds === 0) {
      return; // Stop the countdown if seconds reach 0
    }

    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    // Clear the interval when the component unmounts or when seconds reach 0
    return () => clearInterval(interval);
  }, [seconds]);

  return (

    <div className="">
      {Loading && <Loader/>}
      <Router>
        <ToastContainer theme="colored" />
        <Routes>
          <Route path={`${Config.appUrl}`} Component={Index} />
          <Route path={`${Config.appUrl}get-in-touch`} Component={Contact} />

          <Route path={`${Config.appUrl}all-projects`} Component={AllSectors} />

          <Route path={`${Config.appUrl}projectdetails/:projectname`} Component={Swarnim1} />

          <Route path={`${Config.appUrl}about-us`} Component={AboutUs} />
          <Route path={`${Config.appUrl}register`} Component={RegisterForm} />

          <Route path={`${Config.appUrl}refund-policy/:policyName`} Component={SwarnimFirst} />
          <Route path={`${Config.appUrl}affordable/:policyName`} Component={AffordablePolicy} />

          <Route path={`${Config.appUrl}gallery/:projectname`} Component={Gallery} />

          <Route path={`${Config.appUrl}refund/:slug`} Component={Refund} />

          <Route path={`${Config.appUrl}blogdetail/:slug`} Component={BlogDetail} />

          <Route path={`${Config.appUrl}blogs`} Component={Blogs} />

          <Route path={`${Config.appUrl}news-updates`} Component={NewsUpdate} />

          <Route path={`${Config.appUrl}news`} Component={PressReleaseList} />
          <Route path={`${Config.appUrl}news-details/:slug`} Component={PressRelease} />

          <Route path={`${Config.appUrl}refund-policy`} Component={RefundPolicy}/>
          <Route path={`${Config.appUrl}privacy-policy`} Component={PrivacyPolicy}/>
          {/*  */}
          <Route path={`${Config.appUrl}unsubscribe`} Component={NewsLetter_UnSubscribe}/>
          <Route path={`${Config.appUrl}testimonials`} Component={Testimonials}/>
          <Route path={`${Config.appUrl}refund-testimonials`} Component={RefundTestimonials}/>
        </Routes>
      </Router>
      <Modal
        open={IpLocation}
        closable={false}
        footer={false}
        centered
        title="Please Verify Yourself to Continue"
      >
        <hr/>
        {
          OTPSent ? (
              <>
              <div className='form-field'>
                <label htmlFor="">Enter {length} digits OTP</label>
                <div className="row mt-3">

                  {otpValues.map((value, index) => (
                    <div key={index} className="col px-1">
                      <input
                        key={index}
                        ref={inputRefs[index]}
                        type="text"
                        className='fs-6 form-field bg-light border border-2 text-center px-1 py-2 shadow-sm text-dark'
                        maxLength={1}
                        value={value}
                        onChange={(e) => handleInputChange(index, e)}
                        required
                      />
                    </div>
                  ))}
                  {/* <button onClick={(e) => onHandleSubmit(e)} className="submit-btn fw-bold border-white w-auto mt-3 py-2">Verify OTP</button> */}
                </div>
                <div className='mt-3 top-form-section d-flex justify-content-between align-items-start text-white mt-2'>
                  <p className="text-dark">Resend otp after {seconds} Sec</p>
                  <button onClick={onHandleSubmit}
                    className='p-3 py-1  border border-2 text-dark fs-6 opacity-4'
                    disabled={seconds != 0}
                    type='button'>
                    Resend
                  </button>
                </div>
                <button onClick={(e) => HandleVerify(e)} className="button fw-bold border-white w-auto mt-3 py-2">Verify OTP</button>
              </div>
              </>
          ) : (
            <>
              <div className="mt-3">
                <div className="form-group mb-4">
                  <label className="fw-bold mb-2 fs-6">Name</label>
                  <input 
                    type="text" 
                    className="form-field bg-light border border-2" 
                    placeholder="Enter Your Name"
                    value={Name}
                    onChange={(e) => SetName(e.target.value)}
                  >
                  </input>
                  {Errors?.Name && <span className="text-danger">* {Errors?.Name}</span>}
                </div>
                <div className="form-group mb-4">
                  <label className="fw-bold fs-6">Email</label>
                  <input type="text" 
                    className="form-field bg-light border border-2" 
                    placeholder="Enter Your Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                  >
                  </input>
                  {Errors?.email && <span className="text-danger" >* {Errors?.email}</span>}
                </div>
                <div className="form-group">
                  <label className="fw-bold fs-6">Phone Number</label>
                  <input 
                    type="text" 
                    className="form-field bg-light border border-2" 
                    placeholder="Enter Your Phone Number"
                    value={Phone}
                    maxLength={10}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => setPhone(e.target.value)}  
                  >
                  </input>
                  {Errors?.phone && <span className="text-danger">* {Errors?.phone}</span>}
                </div>
                <button onClick={(e) => onHandleSubmit(e)} className="button fw-bold border-white w-auto mt-3 py-2">Send OTP</button>
              </div>
            </>
          )
        }

      </Modal>
    </div>
  );
}

export default App;
