import React, { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'
import logo from "../assets/Images/deen-dayal.png";
import axios from 'axios';
import { Config } from '../Config';
import NewsLetter from './Elements/NewsLetter';
import { Link } from 'react-router-dom';

const Footer = () => {
    const [contactSection, setContactSection] = useState({});
    const [contactData, setContactData] = useState({});
    const [subscribeData, setSubscribeData] = useState({});

    const footerlink = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.9580832257875!2d77.30887157353759!3d28.420521640171373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cddbec95ed237%3A0x82bbb908404e8e68!2s110%2C%20Sector%2019%20Rd%2C%20Sector%2019%2C%20Faridabad%2C%20Haryana%20121002!5e0!3m2!1sen!2sin!4v1711945870699!5m2!1sen!2sin";


    useEffect(() => {
        axios.get(`${Config.apiUrl}/contactpage`,
            {
                headers: {
                    //'Authorization': 'Bearer ',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                const data = response.data.contact_page;
                setContactSection(data.contact_section);
                setContactData(data.contact_data);
                setSubscribeData(data.newsletter_section);
            })
            .catch(error => {
                console.error('There was a problem fetching the data:', error);
            });
    }, []);

    const socialLinks = contactData.link ? contactData.link.split(',') : [];
    const socialIcons = contactData.icon ? contactData.icon.split(',') : [];

    return (
        <footer className=" border-top border-success footer mt-1">
            <NewsLetter subscribeData={subscribeData} />
            <section className="py-5">
                <Marquee>
                    <div className=" py-2 text-red fw-bold text-center">

                        IMPORTANT NOTE :- REGISTRATION AMOUNT IS REFUNDABLE FOR APPLICANTS WHO ARE NOT SUCCESSFUL

                    </div>
                </Marquee>
                <div className="container-box pb-md-4 pt-5">
                    <div className="row align-items-center justify-content-evenly ">
                        <div className='col-md-6 '>
                            <div className="d-flex flex-column flex-xl-row gap-5">
                                <div>
                                    <div className="align-self-start mb-3">
                                        <img src={logo} alt="" width={100} />
                                    </div>
                                    <div className='social-links d-flex mb-4'>
                                        {socialLinks.map((link, index) => (
                                            <div key={index} className='social-circle mx-2'>
                                                <a href={link} target="_blank" rel="noopener noreferrer">
                                                    <i className={socialIcons[index]}></i>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h4 className='fw-bold text-green mb-3'>
                                        Contact Us
                                    </h4>
                                    <div className="d-flex align-items-center my-2">
                                        <i className="bi bi-envelope-fill fs-5 text-green me-3"></i>
                                        <p className="mb-0">
                                            <a
                                                href={`mailto:${contactSection.contact_mails}`}
                                                className="text-decoration-none text-dark"
                                            >
                                                <b>Email  : </b> {contactSection.contact_mails}
                                            </a>
                                        </p>
                                    </div>

                                    <div className="d-flex align-items-center my-2">
                                        <i className="bi bi-telephone-fill me-3 fs-5 text-green"></i>
                                        <p className="mb-0">
                                            <a
                                                href={`tel:${contactSection.contact_numbers}`}
                                                className="text-decoration-none text-dark"
                                            >
                                                <b>Phone  : </b> {contactSection.contact_numbers}
                                            </a>
                                        </p>
                                    </div>

                                    <div className="d-flex align-items-center my-2 ">
                                        <i className="bi bi-geo-alt-fill fs-5 text-green me-3"></i>
                                        <p className="mb-0">
                                            <b>Office  : </b> {contactSection.contact_addresses}
                                        </p>
                                    </div>  
                                </div>
                            </div>
                            
                        </div>

                        <div className="col-md-6 col-11 mt-md-0 mt-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className='fw-bold text-green mb-4'>
                                        Quick Links
                                    </h4>
                                    <div>
                                        <div className='mb-2'>
                                            <Link to={`${Config.appUrl}about-us`} className='text-dark text-decoration-none'>About Us</Link></div>
                                        <div className='mb-2'>
                                            <Link to={`${Config.appUrl}blogs`} className='text-dark text-decoration-none'>Blogs</Link></div>
                                        <div className='mb-2'>
                                            <Link to={`${Config.appUrl}get-in-touch`} className='text-dark text-decoration-none'>Contact Us</Link></div>
                                        <div className='mb-2'>
                                            <Link to={`${Config.appUrl}news`} className='text-dark text-decoration-none'>News</Link></div>
                                        
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4 className='fw-bold text-green mb-4'>
                                        Policy
                                    </h4>
                                    <div className='mb-2'>
                                        <Link to={`${Config.appUrl}refund-policy`} className='text-dark text-decoration-none'>Refund Policy</Link></div>
                                    <div className='mb-2'>
                                        <Link to={`${Config.appUrl}privacy-policy`} className='text-dark text-decoration-none'>Privacy Policy</Link></div>
                                    <div className='mb-2'>
                                        <Link to={`${Config.appUrl}testimonials`} className='text-dark text-decoration-none'>Testimonials</Link></div>
                                    <div className='mb-2'>
                                        <Link to={`${Config.appUrl}refund-testimonials`} className='text-dark text-decoration-none'>Refund Customer Testimonials</Link></div>
                                </div>
                                {/* <div className="col-md-7 mt-md-0 mt-4 border border-2 pb-0 bg-white p-1">
                                    <iframe src={contactSection.latitude} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='map ' style={{ maxHeight: '200px' }}></iframe>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-md-5 col-11  ">
                            

                        </div>
                    </div>
                </div>
            </section>
            <div className='p-4 text-white bg-green '>
                <div>
                    <p className='paragraph mt-0 fs-6 text-white'>
                        Welcome to DDJAY. It is a place that accommodates your dreams perfectly.
                    </p>
                    
                </div>
                <div className="text-center mt-4 px-4">
                    <span className='fw-bold fs-5'>
                        {" "}
                        Copyrights © {new Date().getFullYear()} Deendayalplot.com | Powered by 
                        <a href="https://www.deendayalplot.com/" target='_blank' className='text-white text-decoration-none'> Deendayalplot.com</a>
                        {/* Deen Dayal Jan Awas Yojna | Powered by Deen Dayal Jan Awas Yojna */}
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default Footer