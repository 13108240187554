import axios from 'axios';
import React, { useState } from 'react'
import { Config } from '../../Config';
import { notification } from 'antd';

const NewsLetter = ({ subscribeData }) => {

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const validateEmail = (email) => {
        const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return regex.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateEmail(email)) {
            setLoading(true);
            axios.post(`${Config.apiUrl}/newsletter/email`, { email },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if (response.data.message === "Email is already subscribed.") {
                        setError("You are Already Subscribed!")
                        return;
                    }

                    if (response.data.message === "Successfully subscribed.") {
                        setEmail("")
                        setError("")

                        notification.open({
                            message: (
                                <div className="d-flex align-items-center">
                                    <div className="pr-20">
                                        <i
                                            className="bi bi-check2-circle text-success me-2"
                                            style={{ fontSize: "20px" }}
                                        ></i>
                                    </div>
                                    <div className="succes-message">
                                        Your subscription recorded!
                                    </div>
                                </div>
                            )
                        });
                    }
                })
                .catch(error => {
                    console.error('There was a problem fetching the data:', error);
                }).finally(() => {
                    setLoading(false)
                })
        } else {
            setError("Please enter a valid email.");
        }
    };

    const handleChange = (e) => {
        setEmail(e.target.value);
        if (error)
            setError("")
    };


    return (
        <div className='newsletter_bg d-md-flex justify-content-between align-items-center px-sm-5 px-4 py-5'
            style={{
                backgroundImage: `linear-gradient(#000000b7, #000000b7), url(${Config.imageUrl + '/' + subscribeData?.newsletter_bg})`
            }}
        >
            <div className='mb-md-0 mb-4' >
                <h3 className='mb-0 fw-semibold display-6 text-white'>{subscribeData?.newsletter_title}</h3>
            </div>
            <div  >
                <form onSubmit={handleSubmit} className="subscribe-form d-md-flex align-items-start">
                    <div>
                        <input
                            type="email"
                            value={email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            className="email-input"
                            required
                        />
                        <p className='text-white mb-0'>{error}</p>
                    </div>

                    <button type="submit" className="subscribe-button" disabled={loading}>
                        <i className={`bi bi-arrow-repeat ${loading && 'd-inline-block'}  loader me-2`}></i>  {subscribeData?.newsletter_button}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default NewsLetter