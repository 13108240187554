import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import swal from 'sweetalert';
import { Config } from '../Config';
import moment from 'moment';

const SectorInformation = ({ schemaInfo, projectInfo, index }) => {

    const [valid, setValid] = useState(true);
    const [imgValid, setImgValid] = useState(true);
    const [drop_sizes, setdrop_sizes] = useState([]);

    const navigationFunction = () => {
        swal("", schemaInfo.msg, "error")
    };

    useEffect(() => {
        const projectName = schemaInfo.project_name;

        const isProjectValid = (
            projectInfo.intro.filter(term => term.project_name == projectName).length > 0 ||
            projectInfo.price.filter(item => item.project_name == projectName).length > 0 ||
            projectInfo.project_download.filter(item => item.project_name == projectName).length > 0 ||
            projectInfo.terms.filter(term => term.project_name == projectName).length > 0
        );

        setdrop_sizes(projectInfo.price?.filter(p => p.project_name == projectName)?.map((p) => (
            {
                size: p.size,
                title: `${p.size} Sq ft, Amount ${p.registration_amount}`,
            }
        )))

        setValid(isProjectValid);

        const isImgValid = (
            projectInfo.gallery.filter(term => term.project_name == projectName).length > 0
        );
        setImgValid(isImgValid);


    }, [projectInfo]);

    const navigate = useNavigate()
    return (
        <section className={`py-5 ${index % 2 === 0 && 'bg-green-blue'}`}>
            <div className='container-box py-md-2'>
                <div className="text-center">
                    <h2 className="fw-bold text-capitalize ">{schemaInfo.project_name}</h2>
                    <div className="line mt-4 mb-5 mx-auto"></div>
                </div>
                <div className="row">
                    <div className="col-md-4 px-md-3">
                        <div>
                            <h6 className="px-4 py-3 bg-green rounded text-white text-center" style={{ letterSpacing: "1px" }}>Scheme Information</h6>

                            <div className='bd-ul-none' dangerouslySetInnerHTML={{
                                __html: schemaInfo.scheme_info
                                    .replace('<ol>', '<ul>')
                                    .replace('</ol>', '</ul>')
                                    .replace(/(<li\b[^>]*>)/g, '$1<i class="bi bi-arrow-right-circle-fill fs-5 me-2 text-secondary"></i>')
                            }} />

                        </div>
                    </div>

                    <div className="col-md-4 py-md-0 py-5 px-md-3">
                        <div >
                            <h6 className="px-4 py-3 bg-green rounded text-white text-center" style={{ letterSpacing: "1px" }}>
                                Location Benefits
                            </h6>

                            <div className='bd-ul-none'
                                dangerouslySetInnerHTML={{
                                    __html: schemaInfo.location_info
                                        .replace('<ol>', '<ul>')
                                        .replace('</ol>', '</ul>')
                                        .replace(/(<li\b[^>]*>)/g, '$1<i class="bi bi-arrow-right-circle-fill fs-5 me-2 text-secondary"></i>')
                                }} />

                        </div>
                    </div>

                    <div className="col-md-4 px-md-3">

                        <div >
                            <h6 className="px-4 py-3 bg-green rounded text-white text-center" style={{ letterSpacing: "1px" }}>
                                Project Details</h6>

                            <div className="list mt-3">

                                {schemaInfo.status !== 2 && (
                                    <div className="d-flex align-items-center">
                                        <i className="bi bi-arrow-right-circle-fill fs-5 me-2 text-secondary"></i>
                                        {schemaInfo.status === 0 ? (
                                            <div className='text-dark d-flex w-100 disabled-link' style={{ color: 'red', cursor: 'pointer' }} onClick={() => navigationFunction(false)}>
                                                {schemaInfo.project_text}
                                                <span className='text-animated rounded ms-2 badge lh-sm' style={{ fontSize: "13px" }}>
                                                    <i className='bi bi-arrow-left me-1'></i>
                                                </span>
                                            </div>
                                        ) : (
                                            <a
                                                onClick={() => {
                                                    localStorage.setItem('projectName', schemaInfo.project_name)
                                                    navigate(`${Config.appUrl}register`, {
                                                        state: {
                                                            index: index,
                                                            project: schemaInfo.project_name,
                                                            //sizes: drop_sizes
                                                        }
                                                    })
                                                }}
                                                className='text-decoration-none text-dark d-flex w-100 cursor-pointer'
                                            >
                                                {schemaInfo.project_text}
                                                <span className='text-animated rounded ms-2 badge lh-sm' style={{ fontSize: "13px" }}>
                                                    <i className='bi bi-arrow-left me-1'></i>Registration Now
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                )}


                                <div className="d-flex align-items-center">
                                    <i className="bi bi-arrow-right-circle-fill fs-5 me-2 text-secondary"></i>
                                    <a href={``} className='text-decoration-none text-dark d-flex w-100'>{schemaInfo.project_text2}
                                    </a>
                                </div>

                                <div className="d-flex align-items-center">
                                    <i className="bi bi-arrow-right-circle-fill fs-5 me-2 text-secondary"></i>
                                    <Link
                                        to={`${Config.appUrl}projectdetails/${schemaInfo.project_name.replaceAll(" ", "-").toLowerCase()}`}
                                        state={{ index: index, project: schemaInfo.project_name }}
                                        className='text-decoration-none text-dark d-flex w-100'
                                    >
                                        {schemaInfo.project_text3}
                                        <span className='text-animated rounded ms-2 badge lh-sm' style={{ fontSize: "13px" }}>
                                            <i className='bi bi-arrow-left me-1'></i> Click here
                                        </span>
                                    </Link>
                                    {/* {valid === false ? (
                                        <div
                                            className='text-decoration-none text-dark d-flex w-100'
                                            onClick={() => swal("", "Project Details Not Found", "warning")}
                                            style={{ color: 'red', cursor: 'pointer' }}
                                        >
                                            {schemaInfo.project_text3}
                                            <span className='text-animated rounded ms-2 badge lh-sm' style={{ fontSize: "13px" }}>
                                                <i className='bi bi-arrow-left me-1'></i> Click here
                                            </span>
                                        </div>
                                    ) : (
                                        
                                    )} */}
                                </div>

                                <div className="d-flex align-items-center">
                                    <i className="bi bi-arrow-right-circle-fill fs-5 me-2 text-secondary"></i>
                                    {imgValid === false ? (
                                        <div
                                            className='text-decoration-none text-dark d-flex w-100'
                                            onClick={() => swal("", "Gallery Section Not Found", "warning")}
                                            style={{ color: 'red', cursor: 'pointer' }}
                                        >Gallery

                                            <span className='text-animated rounded ms-2 badge lh-sm' style={{ fontSize: "13px" }}>
                                                <i className='bi bi-arrow-left me-1'></i> Click here
                                            </span>
                                        </div>

                                    ) : (
                                        <Link
                                            to={`${Config.appUrl}gallery/${schemaInfo.project_name.replaceAll(" ", "-").toLowerCase()}`}
                                            state={{ index: index, project: schemaInfo.project_name }}
                                            className='text-decoration-none text-dark d-flex w-100'>Gallery
                                            <span className='text-animated rounded ms-2 badge lh-sm' style={{ fontSize: "13px" }}>
                                                <i className='bi bi-arrow-left me-1'></i> Click here
                                            </span>
                                        </Link>

                                    )}

                                </div>

                                <div className="d-flex align-items-center">
                                    <i className="bi bi-arrow-right-circle-fill fs-5 me-2 text-secondary"></i>
                                    <a className='text-decoration-none text-dark d-flex w-100'>Allotment Date : <span className='fw-bold'>{moment(schemaInfo.allotment_date).format("DD-MMM-YYYY")}</span>
                                    </a>
                                </div>

                                <div className="d-flex align-items-center">
                                    <i className="bi bi-arrow-right-circle-fill fs-5 me-2 text-secondary"></i>
                                    <a className='text-decoration-none text-dark d-flex w-100'>Last Date : <span className='fw-bold'>{moment(schemaInfo.last_date).format("DD-MMM-YYYY")}</span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section >

    )
}

export default SectorInformation