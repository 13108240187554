import React, { useEffect, useRef, useState } from 'react'
import ListBox from './Elements/ListBox'
import ImageSlider from './ImageSlider';
import { Config } from '../Config';
import Marquee from 'react-fast-marquee';
import Navbar from './Navbar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Coming from "../assets/Images/Coming-soon.jpg"

const NewMainHomeSection = ({ data ,activeindex }) => {

  const [currentSlide, setCurrentSlide] = useState(0);
  const [projectInfo, setProjectInfo] = useState([])

    
  const settings = {
    dots: true,
    infinite: projectInfo?.list?.length > 1 ? true : false,
    speed: 500,
    autoplay : true,
    autoplaySpeed : 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    afterChange: (current) => handleChangeSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
    appendDots: (dots) => (
      <div style={{ padding: "10px" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "6px",
          height: "6px",
          backgroundColor: "gray",
          borderRadius: "50%",
          display: "inline-block",
          margin: "0 4px",
        }}
      ></div>
    ),
  };

  useEffect(() => {

    setProjectInfo(
      {
        heading: {
          text: "Ongoing Projects",
          bgcolor: "bg-green"
        },
        list: data?.mainHeaderData?.project_info
          .filter(pr => pr.status === 1)
          .reverse()
          .map(item => {

            const matchedDownload = data?.downloadData?.find(downItem => downItem?.list.some(subItem => subItem?.id === item?.id));

            return{
            id:item?.id,
            banner_text:item?.banner_text,
            bank_name	: item?.bank_name,
            title_sm: "",
            last_date : item?.last_date,
            description  : item?.description,
            allotment_date : item?.allotment_date,
            title_lg: item.project_name,
            scheme_info : item?.scheme_info,
            location_info : item?.location_info,
            name: item.project_name,
            project_text2 : item?.project_text2,
            url: `${Config.appUrl}projectdetails/${item.project_name.replaceAll(" ", "-").toLowerCase()}`,
            downloadData: matchedDownload,
            };
          })
      }
    )
  }, [data]);

  
  const [ProjectData, setProjectData] = useState({});
  const [expiry, setExpiry] = useState(true);
  const [countdownTime, setCountdownTime] = useState({}); // Store countdown for each project

  // Countdown timer effect for each project
  const countdownTimer = (projectExpiryTime, projectId) => {
    const timeInterval = setInterval(() => {
      const countdownDateTime = new Date(projectExpiryTime).getTime();
      const currentTime = new Date().getTime();
      const remainingTime = countdownDateTime - currentTime;

      if (remainingTime < 0) {
        clearInterval(timeInterval);
        setExpiry(false);
      } else {
        const totalDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const totalHours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const totalMinutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const totalSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        // Update the countdown for the specific project
        setCountdownTime((prev) => ({
          ...prev,
          [projectId]: {
            countdownDays: totalDays,
            countdownHours: totalHours,
            countdownMinutes: totalMinutes,
            countdownSeconds: totalSeconds,
          }
        }));
      }
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(timeInterval);
  };

  const [Step, setStep] = useState(0);
  useEffect(() => {
    if (projectInfo?.list?.length > 0 && projectInfo?.list[Step]) {
      setProjectData(projectInfo?.list[Step]);
    }
  }, [Step, projectInfo]);

  // Get background image for each project in the slider
  const ContainerRef = useRef();
  const [containerHeight, setContainerHeight] = useState("auto");

  useEffect(() => {
    if (ContainerRef.current) {
      setContainerHeight(ContainerRef.current.offsetHeight); // Update height based on the container
    }
  }, [data, ProjectData]);

  // Start countdown for each project when the component mounts
  useEffect(() => {
    projectInfo?.list?.forEach((item) => {
      const projectExpiryTime = new Date(item?.last_date).toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      });

      if (projectExpiryTime) {
        countdownTimer(projectExpiryTime, item.id); // Start countdown for this specific project
      }
    });
  }, [projectInfo?.list]);


  const sliderRef = useRef(null);
  const containerRef = useRef();

  const handleChangeSlide = (index) => {
    setCurrentSlide(index);  // Update the active slide index
    sliderRef.current.slickGoTo(index);  // Move the slider to the selected slide
    activeindex(index);

    if (containerRef.current) {
      
      const itemHeight = containerRef.current.children[index]?.offsetHeight;
      containerRef.current.scrollTop = itemHeight * index;  // Scroll the container by index * item height
    }
  };
  
  // Click on a project to change the slide and scroll
  const handleProjectClick = (index) => {
    handleChangeSlide(index);  // Update slide and scroll position
  };

  
  return (
    <section className="pt-4 mt-2 ">
      <div className="container-box">
        <div className="d-flex flex-column flex-xl-row justify-content-center align-items-center text-center border-bottom bg-white mb-4 gap-3 pe-3" style={{flex:1}}>
          {/* <img src={`${Config.imageUrl}/${data.banner_img}`} alt="banner" className="img-fluid border p-1 Main-Banner-img" /> */}
          
        </div>
        <div className='row'>
          <div className='col-xl-9'>
          <img src={`${Config.imageUrl}/${data.banner_img}`} alt="banner" className="img-fluid p-1 pb-4" />
            {
              projectInfo?.list?.length === 0 ? (
                <div className='text-center'>
                    <img src={Coming} className='w-75'/>
                </div>
              ):(
                <Slider ref={sliderRef} {...settings}>
                  {projectInfo?.list?.map((item, index) => {
                    const projectImage = data?.mainHeaderData?.gallery?.find(image => image?.project_id === item?.id); // Get the image specific to the current project
                    const backgroundImage = projectImage?.image || ''; // Default to empty string if no image found
                    
                    return (
                      <div className="w-100" key={index}>
                        <div
                          className='px-2'
                          style={{
                            backgroundImage: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${Config.imageUrl}/gallery/${backgroundImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize:"cover",
                            backgroundPosition:"center",
                            height: "450px",
                            borderRadius:"15px"
                          }}
                        >
                          <div className=' d-flex flex-column flex-xl-row align-items-end justify-content-between py-xl-5 px-xl-3 h-100'>
                            <div className='col-xl-6 px-3 mb-2 mb-xl-4 mt-4 mt-xl-5 mt-xl-0'>
                              <div className='text-white fs-5'>
                                Last Date : <span class="text-animated-1 fw-bold p-1">{moment(item?.last_date).format("DD/MM/YYYY")}</span>
                              </div>
                              <div className='text-white fs-5 mt-3'>
                                Allotement Date : <span class="text-animated-1 fw-bold p-1">{moment(item?.allotment_date).format("DD/MM/YYYY")}</span>
                              </div>
                            </div>
                            <div className='h-100 px-3 d-flex flex-column justify-content-between gap-3 col-xl-6'>
                              <div className='text-end'>
                                <h2 className='text-white'>{item?.title_lg}</h2>
                                <div className='bg-white mt-3' style={{ height: "5px" }}></div>
                                <Link to={item?.url} state={{ index: index, project: item.name }} className=" btn ongoing-button mt-3">
                                  View More
                                </Link>
                                
                              </div>
                              <div>
                                <h4 className='text-white'>{item?.project_text2}</h4>
                                <div className='text-black fs-6 mb-2 text-white'>{item?.banner_text}</div>
                                <div className="mt-1 py-1">
                                  <div className="btn-group d-flex flex-wrap" style={{ width: "100%" }}>
                                    <button type="button" className="btn btn-custom-outline-success mb-2" style={{ fontSize: '15px' }}>
                                      {countdownTime[item.id]?.countdownDays || 0} <div>Days</div>
                                    </button>
                                    <button type="button" className="btn btn-custom-outline-success mb-2" style={{ fontSize: '15px' }}>
                                      {countdownTime[item.id]?.countdownHours} <div>Hours</div>
                                    </button>
                                    <button type="button" className="btn btn-custom-outline-success mb-2" style={{ fontSize: '15px' }}>
                                      {countdownTime[item.id]?.countdownMinutes} <div>Minutes</div>
                                    </button>
                                    <button type="button" className="btn btn-custom-outline-success mb-2" style={{ fontSize: '15px' }}>
                                      {countdownTime[item.id]?.countdownSeconds} <div>Seconds</div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              )
            } 
          </div>
          <div className='col-xl-3 mt-3 mt-xl-0'>
            <div className=''>
              <h5 style={{borderRadius:"10px 10px 0px 0px"}} className='py-3 text-white my-xl-0 text-center bg-green'>Ongoing Projects</h5>
            </div>
            <div className='ongoing-project' ref={containerRef}>
                {
                  projectInfo?.list?.map((item,index) => {
                    return(
                      <div className={`border-top d-flex align-items-center py-3 py-xl-0 ms-1 ms-xl-0 ${currentSlide === index ? 'Active' : ''}`} onClick={() => handleProjectClick(index)}>
                        <i className={`bi bi-check-all fs-5 ms-2`}></i>
                        <div className='d-flex flex-column justify-content-center'>
                          <h6 className='mb-0'>{item?.title_lg}</h6>
                          <Link to={item.url}
                              state={{ index: index, project: item.name }}
                              className={`${index === Step ? "text-white" : "text-dark"} text-decoration-none ps-0`}>
                              <span className='text-animated d-inline  rounded badge lh-sm' style={{ fontSize: "13px" }}> 
                                  <>
                                  <i className='bi bi-arrow-left fs-6 me-1'></i> Registration Now
                                  </>
                              </span>
                          </Link>
                        </div>
                      </div>
                    )
                  })
                }
                {
                  projectInfo?.list?.length === 0 && (
                    <h5 className='text-center mt-4'>Coming Soon</h5>
                  )
                }
              </div>
              <div>
                <h5 style={{borderRadius:"10px 10px 0px 0px"}} className='py-3 mt-3 mb-0 text-white text-center bg-green'>Available Bank Loans</h5>
              </div>
              <div className='ongoing-project mt-0' style={{height:"110px"}}>
              {
                  // Find the bank name of the active project
                  projectInfo?.list?.map((item, index) => {
                    if (currentSlide === index) {
                      const bankName = item?.bank_name; // Replace `bankName` with the correct field
                      return (
                        <div key={index} className="bank-name p-0">
                          {
                            bankName?.split(",")?.map((item) => (
                              <h6 className='p-3 mb-0 border-bottom'>
                                <i class="bi bi-check-all ms-2"></i>{" "}{item}
                              </h6>
                            ))
                          }
                        </div>
                      );
                    }
                    return null; // Don't render anything if it's not the active project
                  })
                }
                {
                  projectInfo?.list?.length === 0 && (
                    <h5 className='text-center mt-4'>Coming Soon</h5>
                  )
                }
              </div>
              <div>
                <h5 style={{borderRadius:"10px 10px 0px 0px"}} className='py-3 mt-3 mb-0 text-white text-center bg-green'>Downloads</h5>
              </div>
              <div className='ongoing-project mt-0' style={{height:"120px"}}>
              {
                // Map through downloadData
                data?.downloadData?.map((item, index) => {
                  // Check if the project ID of the active project matches with the current downloadData
                  const activeProject = projectInfo?.list?.[currentSlide]; // Get active project based on currentSlide
                  if (activeProject?.id === item?.heading?.id) { // Compare project ID
                    return (
                      <div key={index} className='p-0 px-2' style={{minHeight:'fit-content'}}>
                        {item.list?.map((listItem, listIndex) => (
                          <Link target='_blank' to={`${Config.docUrl}${listItem.file}`} download={true} key={listIndex} className='text-dark d-block text-decoration-none border-bottom p-3 mb-0' >
                            <i className='bi bi-download me-2'></i>{listItem?.title_sm}
                          </Link>
                        ))}
                        {
                          item?.list?.length === 0 && (
                            <div className='px-0 py-0' style={{minHeight:'fit-content'}}>No File Found</div>
                          )
                        }
                      </div>
                    );
                  }
                  return null; // Don't render anything if the project ID doesn't match
                })
              }
              {
                  projectInfo?.list?.length === 0 && (
                    <h5 className='text-center mt-4'>Coming Soon</h5>
                  )
                }
            </div>
          </div>
        </div>
        
      </div>
    </section>
  );
}

export default NewMainHomeSection