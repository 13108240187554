import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notification } from "antd";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Loader from "./Loader";
import axios from "axios";
import { Config } from "../Config";

const NewsLetter_UnSubscribe = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(null);  // Holds the confirmation status (Yes/No)
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setEmail(params.get('email'));
    }, [location]);


    // Handle form submission (API call to unsubscribe)
    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        try {
            // Replace with your actual unsubscribe API call
            const response = await axios.post( `${Config.apiUrl}/unsubscribe` , {
                Email : email,
            });

            if (response?.data?.success === true) {
                notification.open({
                    message: (
                      <div className="d-flex align-items-center">
                        <div className="pr-20">
                          <i
                            className="bi bi-check2-circle text-success me-2"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </div>
                        <div className="succes-message">NewsLetter unsubscribe successfull</div>
                      </div>
                    ),
                });
                setTimeout(() => {
                    navigate(`${Config.appUrl}`)
                }, 1000);
            }
        } catch (error) {
            notification.open({
                message: (
                  <div className="d-flex align-items-center">
                    <div className="pr-20">
                      <i
                        className="bi bi-x-circle text-danger me-2"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </div>
                    <div className="succes-message">Sorry Something went Wrong. </div>
                  </div>
                ),
                description: error?.message,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>   
            {loading && <Loader />}
            <Navbar/>
            <div className="container py-5 my-5">
                <div className="section-padding text-center">
                    <h2 className="text-yellow text-center">Are you sure about unsubscribing?</h2>
                    <div className="d-flex justify-content-center gap-4 mt-4">
                        <button className="btn" style={{border:"1px solid #03A659"}} onClick={() => navigate(`${Config.appUrl}`)}>
                           <i className="bi bi-arrow-left me-2"></i> Go Back
                        </button>
                        <button onClick={handleSubmit} style={{width:"fit-content"}} className="button fw-bold border-white d-flex align-items-center text-white bg-gold h-100">
                            Unsubscribe <i className="bi bi-arrow-right ms-2"></i>
                        </button>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default NewsLetter_UnSubscribe;
